//#region Variable.less (AA Brand Colors)
//Black
$aa-black: #131313;
$aa-black-90: #131313e3; // About 90% opacity
$soft-black: #2c2c2c;
$aa-shade-black: #333;
//Blue
$aa-blue: #0078d2;
$aa-bright-blue: #007aff;
$b4-blue: #007bff;
$aa-light-blue: #4db4fa;
$aa-dark-blue: #00467f;
$aa-highlight-blue: #e1f0fa;
$light-blue-hover: #e5f1ff;
$aa-dark-blue-2: #004da0;
//Brown
$brown: #8b4513;
//Gray
$aa-gray: #9da6ab;
$aa-medium-gray: #6e8999;
$aa-light-gray: #d0dae0;
$aa-ultra-light-gray: #eeeeee;
$aa-dark-gray: #36495a;
$aa-medium-dark-gray: #777;
$aa-shade-gray: #f8f8f8;
//Green
$aa-green: #00b989;
$aa-dark-green: #008763;
//Orange
$aa-orange: #ff7318;
$aa-light-orange: #faaf00;
$aa-normal-orange: #fb934a;
//Pink
$light-pink: #fcdadc;
//Red
$aa-red: #f52305;
$aa-dark-red: #c30019;
$light-red: #ff6d6d;
//Teal
$aa-teal: #b1e1eb;
$aa-light-teal: #9dc4e9;
//White
$aa-white: #ffffff;
//Yellow
$aa-yellow: #ffff6b;
$aa-yellow-green: #d1d532;
//Pink
$aa-pink: #be61e9;

$zero: 0rem;
$one: 0.07142857rem;
$two: $one + $one;
$three: $two + $one;
$four: $three + $one;
$five: $four + $one;
$six: $five + $one;
$seven: $six + $one;
$eight: $seven + $one;
$nine: $eight + $one;
$ten: $nine + $one;
$eleven: $ten + $one;
$twelve: $eleven + $one;
$thirteen: $twelve + $one;
$fourteen: $thirteen + $one;
$fifteen: $fourteen + $one;
$sixteen: $fifteen + $one;
$seventeen: $sixteen + $one;
$eighteen: $seventeen + $one;
$nineteen: $eighteen + $one;
$twenty: $nineteen + $one;
$twenty-one: $twenty + $one;
$twenty-two: $twenty + $two;
$twenty-five: $twenty + $five;
$twenty-six: $twenty-five + $one;
$thirty: $twenty + $ten;
$thirty-two: $thirty + $two;
$thirty-four: $thirty-two + $two;
$thirty-six: $eighteen + $eighteen;
$fourty: $twenty + $twenty;
$fourty-one: $fourty + $one;
$fourty-two: $fourty + $two;
$fourty-three: $fourty + $three;
$fifty: $twenty-five + $twenty-five;
$fifty-one: $fifty + $one;
$sixty: $fifty + $ten;
$sixty-four: $sixty + $four;
$seventy: $fifty + $twenty;
$seventy-five: $fifty + $twenty-five;
$eighty-seven: $fifty + $thirty + $seven;
$ninety: $fifty + $fourty;
$hundred: $fifty + $fifty;
$hundred-ten: $hundred + $ten;
$hundred-twenty: $hundred + $twenty;
$hundred-thirty: $hundred-ten + $twenty;
$hundred-ninety: $hundred + $fifty + $fourty;
$hundred-ninety-six: $hundred-ninety + $six;
$two-hundred-twenty: $hundred + $hundred + $twenty;
$four-hundred: $hundred + $hundred + $hundred + $hundred;
$four-hundred-seventy-six: $four-hundred + $seventy + $six;
//#endregion Variable.less (AA Brand Colors)

//#region COLOR
.aa-white {
  color: $aa-white;
}

.aa-highlight-blue {
  color: $aa-highlight-blue;
}

.aa-light-blue {
  color: $aa-light-blue;
}

.aa-teal {
  color: $aa-teal;
}

.aa-dark-blue {
  color: $aa-dark-blue;
}

.aa-bright-blue {
  color: $aa-bright-blue;
}

.aa-blue {
  color: $aa-blue;
}

.aa-light-teal {
  color: $aa-light-teal;
}

.aa-blue-border {
  border-color: $aa-blue;
}

.aa-green {
  color: $aa-green;
}

.aa-dark-green {
  color: $aa-dark-green;
}

.aa-yellow-green {
  color: $aa-yellow-green;
}

.aa-light-orange {
  color: $aa-light-orange;
}

.aa-orange {
  color: $aa-orange;
}

.aa-red {
  color: $aa-red;
}

.aa-dark-red {
  color: $aa-dark-red;
}

.aa-ultra-light-gray {
  color: $aa-ultra-light-gray;
}

.aa-light-gray {
  color: $aa-medium-dark-gray;
}

.aa-gray {
  color: $aa-gray;
}

.aa-medium-gray {
  color: $aa-medium-gray;
}

.aa-dark-gray {
  color: $aa-dark-gray;
}

.aa-black {
  color: $aa-black;
}

.b4-blue {
  color: $b4-blue;
}

.aa-pink {
  color: $aa-pink;
}

//#endregion COLOR

/*#region BACKGROUND COLOR*/
.bg-shade-gray {
  background-color: $aa-shade-gray;
}

.bg-white {
  background-color: $aa-white;
}

.bg-highlight-blue {
  background-color: $aa-highlight-blue;
}

.bg-light-blue {
  background-color: $aa-light-blue;
  color: $aa-white;
}

.bg-teal {
  background-color: $aa-teal;
}

.bg-dark-blue {
  background-color: $aa-dark-blue;
  color: $aa-white;
}

.bg-blue {
  background-color: $aa-blue;
  color: $aa-blue;
}

.bg-green {
  background-color: $aa-green;
}

.bg-yellow-green {
  background-color: $aa-yellow-green;
}

.bg-light-orange {
  background-color: $aa-light-orange;
}

.bg-orange {
  background-color: $aa-orange;
  color: $aa-white;
}

.bg-red {
  background-color: $aa-red;
  color: $aa-white;
}

.bg-dark-red {
  background-color: $aa-dark-red;
  color: $aa-white;
}

.bg-ultra-light-gray {
  background-color: $aa-ultra-light-gray;
}

.bg-light-gray {
  background-color: $aa-medium-dark-gray;
}

.bg-gray {
  background-color: $aa-gray;
}

.bg-medium-gray {
  background-color: $aa-medium-gray;
  color: $aa-white;
}

.bg-dark-gray {
  background-color: $aa-dark-gray;
  color: $aa-white;
}

.bg-black {
  background-color: $aa-black;
  color: $aa-white;
}

.bg-b4-blue {
  background-color: $b4-blue;
  color: $aa-white;
}

.bg-light-pink {
  background-color: $light-pink;
}

/*#endregion BACKGROUND COLOR*/

//#region CSS TO BE APPLIED IN WHOLE APP
html,
body {
  height: 100%;
  overflow: hidden;
  font-family: AmericanSansLight, Helvetica, sans-serif;
  font-size: 14px;
  background-color: $aa-shade-gray;
}

.modal-content {
  border-radius: 1rem;
}

.modal-body {
  padding: 0 !important;
}

.modal-header {
  background-color: $aa-shade-gray;
  border-color: #e7e7e7;
  font-size: $eighteen;
  color: $aa-medium-dark-gray;
  height: $sixty-four;
  padding: 1.5rem 1.5rem;
  border-top-left-radius: calc(1rem - $one);
  border-top-right-radius: calc(1rem - $one);

  .cross {
    height: 1rem;
    width: 1rem;
    padding: 0.75rem;
  }
}

//#endregion CSS TO BE APPLIED IN WHOLE APP

/*#region MARGIN */
.aa-m-0 {
  margin: 0;
}

.aa-m-1 {
  margin: 0.25rem;
}

.aa-m-2 {
  margin: 0.5rem;
}

.aa-m-3 {
  margin: 0.75rem;
}

.aa-m-4 {
  margin: 1rem;
}

.aa-m-5 {
  margin: 1.25rem;
}

//margin-right
.aa-mr-0 {
  margin-right: 0;
}

.aa-mr-1 {
  margin-right: 0.25rem;
}

.aa-mr-2 {
  margin-right: 0.5rem;
}

.aa-mr-3 {
  margin-right: 0.75rem;
}

.aa-mr-4 {
  margin-right: 1rem;
}

.aa-mr-5 {
  margin-right: 1.25rem;
}

//margin-left
.aa-ml-0 {
  margin-left: 0;
}

.aa-ml-1 {
  margin-left: 0.25rem;
}

.aa-ml-2 {
  margin-left: 0.5rem;
}

.aa-ml-3 {
  margin-left: 0.75rem;
}

.aa-ml-4 {
  margin-left: 1rem;
}

.aa-ml-5 {
  margin-left: 1.25rem;
}

//margin-top
.aa-mt-0 {
  margin-top: 0;
}

.aa-mt-2 {
  margin-top: 0.5rem;
}

.aa-mt-3 {
  margin-top: 0.75rem;
}

.aa-mt-4 {
  margin-top: 1rem;
}

.aa-mt-5 {
  margin-top: 1.25rem;
}

//margin-bottom
.aa-mb-0 {
  margin-bottom: 0;
}

.aa-mb-1 {
  margin-bottom: 0.25rem;
}

.aa-mb-2 {
  margin-bottom: 0.5rem;
}

.aa-mb-3 {
  margin-bottom: 0.75rem;
}

.aa-mb-4 {
  margin-bottom: 1rem;
}

.aa-mb-5 {
  margin-bottom: 1.25rem;
}

.aa-mb-8 {
  margin-bottom: 2rem;
}

//margin-inline (left and right)
.aa-mx-0 {
  margin-inline: 0;
}

.aa-mx-2 {
  margin-inline: 0.5rem;
}

.aa-mx-3 {
  margin-inline: 0.75rem;
}

.aa-mx-4 {
  margin-inline: 1rem;
}

.aa-mx-5 {
  margin-inline: 1.25rem;
}

//margin-block (top and bottom)
.aa-my-0 {
  margin-block: 0;
}

.aa-my-2 {
  margin-block: 0.5rem;
}

.aa-my-3 {
  margin-block: 0.75rem;
}

.aa-my-4 {
  margin-block: 1rem;
}

.aa-my-5 {
  margin-block: 1.25rem;
}

//#endregion MARGIN

/*#region PADDING */
.aa-p-0 {
  padding: 0;
}

.aa-p-1 {
  padding: 0.25rem;
}

.aa-p-2 {
  padding: 0.5rem;
}

.aa-p-3 {
  padding: 0.75rem;
}

.aa-p-4 {
  padding: 1rem;
}

.aa-p-5 {
  padding: 1.25rem;
}

//padding-right
.aa-pr-0 {
  padding-right: 0;
}

.aa-pr-1 {
  padding-right: 0.25rem;
}

.aa-pr-2 {
  padding-right: 0.5rem;
}

.aa-pr-3 {
  padding-right: 0.75rem;
}

.aa-pr-4 {
  padding-right: 1rem;
}

.aa-pr-5 {
  padding-right: 1.25rem;
}

//padding-left
.aa-pl-0 {
  padding-left: 0;
}

.aa-pl-1 {
  padding-left: 0.25rem;
}

.aa-pl-2 {
  padding-left: 0.5rem;
}

.aa-pl-3 {
  padding-left: 0.75rem;
}

.aa-pl-4 {
  padding-left: 1rem;
}

.aa-pl-5 {
  padding-left: 1.25rem;
}

.aa-pl-6 {
  padding-left: 1.5rem;
}

.aa-pl-7 {
  padding-left: 1.75rem;
}

.aa-pl-8 {
  padding-left: 2rem;
}

//padding-top
.aa-pt-0 {
  padding-top: 0;
}

.aa-pt-2 {
  padding-top: 0.5rem;
}

.aa-pt-3 {
  padding-top: 0.75rem;
}

.aa-pt-4 {
  padding-top: 1rem;
}

.aa-pt-4-4 {
  padding-top: 1.1rem;
}

.aa-pt-5 {
  padding-top: 1.25rem;
}

//padding-bottom
.aa-pb-0 {
  padding-bottom: 0;
}

.aa-pb-1 {
  padding-bottom: 0.25rem;
}

.aa-pb-2 {
  padding-bottom: 0.5rem;
}

.aa-pb-3 {
  padding-bottom: 0.75rem;
}

.aa-pb-4 {
  padding-bottom: 1rem;
}

.aa-pb-5 {
  padding-bottom: 1.25rem;
}

.aa-pb-6 {
  padding-bottom: 1.5rem;
}

.aa-pb-7 {
  padding-bottom: 1.75rem;
}

.aa-pb-8 {
  padding-bottom: 2rem;
}

//padding-inline (left and right)
.aa-px-0 {
  padding-inline: 0;
}

.aa-px-2 {
  padding-inline: 0.5rem;
}

.aa-px-3 {
  padding-inline: 0.75rem;
}

.aa-px-4 {
  padding-inline: 1rem;
}

.aa-px-5 {
  padding-inline: 1.25rem;
}

//padding-block (top and bottom)
.aa-py-0 {
  padding-block: 0;
}

.aa-py-2 {
  padding-block: 0.5rem;
}

.aa-py-3 {
  padding-block: 0.75rem;
}

.aa-py-4 {
  padding-block: 1rem;
}

.aa-py-5 {
  padding-block: 1.25rem;
}

//#endregion PADDING
//#region Gap
.gap-4 {
  gap: $four;
}

//#endregion Gap

//#region COMMON
.tooltip-inner {
  font-family: AmericanSansLight, Helvetica, sans-serif;
}

.display-flex {
  display: flex;
}

// cannot use in flex container
.ai-center {
  align-items: center;
}

// used in flex container because align-items does not working in flex container
.ac-center {
  align-content: center;
}

.dropdown-width {
  min-width: $hundred;
  width: 100%;
}

.cursor-default {
  cursor: default;
}

.cursor-moveable {
  cursor: move;
}

.text-clickable {
  cursor: pointer;
}

.text-normal {
  font-weight: normal;
}

.text-uppercase {
  text-transform: uppercase;
}

.right {
  float: right;
}

.text-centered {
  text-align: center;
}

.text-right {
  text-align: right;
}

a {
  cursor: pointer;
  color: $aa-bright-blue;
}

.select-link {
  cursor: pointer;
  color: $aa-bright-blue;

  &:hover {
    color: $aa-dark-blue;
  }
}

button[disabled] {
  pointer-events: auto;
}

textarea {
  max-width: 100%;
  min-width: 100%;
}

.custom-textarea {
  height: 100%;
}

.bold-7 {
  font-weight: 700;
}

th {
  font-weight: 300;
  color: #9da6ab;
}

.vertical-scroll-boundary {
  height: 100%;
  overflow: hidden;
}

.title-txt {
  color: $aa-medium-dark-gray;
  line-height: 20px;
  padding: $fifteen;
  font-size: $twenty;
}

.justify-start {
  justify-content: start;
}

.justify-space-between {
  justify-content: space-between;
}

.print-icon {
  cursor: pointer;
  padding-block: 1rem;

  &:hover {
    color: $aa-bright-blue;
  }
}

.no-outline {
  outline: none;
}

.border-red {
  border: $one solid $aa-red !important;
}

//#region PrimeNG changes for whole application
@mixin p-radiobutton-highlight-styles {
  background: none;
  border-width: $four;
}

.p-component,
.p-inputtext {
  font-family: AmericanSansLight, Helvetica, sans-serif;
}

.p-checkbox-label {
  line-height: 1;
  /*TODO: Why you have to adjust these things?*/
  margin-top: 0.5rem;
  margin-left: 0.5rem;
}

.p-datatable .p-datatable-thead > tr > th {
  border: 0;
  border-top: $one solid #dee2e6;
  background: #f8f8f8;
}

.p-inputswitch {
  .p-inputswitch-slider {
    background: lightslategrey;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s;
    border-radius: $thirty;

    &:before {
      border-radius: 50%;
    }
  }

  &:not(.p-disabled):hover {
    .p-inputswitch-slider {
      background: dimgray;
    }
  }
}

.p-checkbox {
  .p-checkbox-box {
    width: $sixteen;
    height: $sixteen;
  }
}

.p-checkbox-checked {
  .p-disabled {
    background: dimgray !important;
    border-color: dimgray !important;
  }
}

.p-radiobutton {
  .p-radiobutton-box {
    width: $sixteen;
    height: $sixteen;

    .p-radiobutton-icon {
      width: $eight;
      height: $eight;
    }

    &.p-highlight {
      @include p-radiobutton-highlight-styles;

      &.p-focus,
      &:not(.p-disabled):hover {
        @include p-radiobutton-highlight-styles;
      }
    }
  }
}

.vertical-align-top {
  vertical-align: top;
}

//#endregion COMMON

/*#region******** GLOBAL HEADER (GH)*******/
.navbar-AA {
  // background-color: $aa-bright-blue !important;
  // font-family: AmericanSansLight, Helvetica, sans-serif;
  // min-height: $fifty;
  // padding-block: $twelve;

  a {
    color: $aa-blue;
  }

  .navbar-nav > li {
    height: $fifty;
    padding: $fourteen $ten;

    a {
      color: $aa-blue;
      text-shadow: none;

      &:focus {
        color: $aa-blue;
      }

      &:hover {
        color: $aa-blue;
      }
    }
  }
}

.gh {
  background-color: $aa-white;

  .logo {
    background: url('/assets/images/global-header/aa-lmo-logo.svg') no-repeat;
    display: inline-block;
    width: $seventy;
    height: $twenty;
  }

  .logo-env {
    color: $aa-bright-blue;
    font-size: $fourteen;
  }

  .navbar-toggler {
    border-color: lightgray;
  }

  .ham {
    color: #888;
    padding-top: $two;
  }

  .global-header-icon {
    width: $thirty-six;
    height: $sixteen;
  }

  .global-header-logo {
    width: $seventy;
  }
}

/*#endregion********GLOBAL HEADER*******/
.clock-margin {
  margin-left: $five;
  color: #fff;
}

/*#region********Custom List control*******/

.indeterminate-checkbox {
  font-size: xx-small;
  font-weight: bold;
}

.indeterminate-checkbox-label {
  justify-content: center;
  display: flex;
  align-items: center;
}

.indeterminate-margin-0-25 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  width: unset;
}

/*#endregion******** CCustom List control)*******/

/* #region Admin */

.admin-notice-error-message {
  color: red;
  font-size: 0.8em;
}

.required-form-field-asterisk {
  color: red;
  margin-left: 2px;
}

.admin-notice-sub-header {
  font: 1em sans-serif;
}

.admin-list {
  list-style-type: none;
  padding: 0;
}

.active-admin-link {
  color: white;
  background-color: $aa-bright-blue;
}

.nav-link.active-admin-link:hover {
  color: white;
  background-color: $aa-bright-blue;
}

.width-100 {
  width: 100%;
}

.admin-content {
  max-height: 90vh;
  overflow-y: auto;
}

.width-10 {
  width: 10%;
}

.width-15 {
  width: 15%;
}

.width-20 {
  width: 20%;
}

.width-40 {
  width: 40%;
}

/* #endregion Admin */

/*#region******** CC VIEW HEADER (CCH)*******/
.cch {
  font-size: $fourteen;
}

.cch-ac {
  width: 5rem;
  text-transform: uppercase;
}

.cch-flt {
  width: 6rem;
}

.cch-stations {
  text-transform: uppercase;
  width: 6rem;
}

.cch-selected-station {
  text-transform: uppercase;
  width: 6rem;
  height: 2.5rem;
  font-size: $fourteen;
  display: inline-flex;
  align-items: baseline;
  justify-content: space-between;
  padding: $five $ten;
  font-weight: normal;
}

.gate {
  .tooltip-inner {
    color: $aa-white;
    background-color: $soft-black;
    max-width: 18rem;
    text-align: left;
    padding: 0.5rem;
  }

  .gate-range-tag {
    height: $twenty-five;
    font-size: $fourteen;
    padding: $five $ten;
    font-weight: normal;
  }

  .gate-toggle {
    padding: 0;
    margin-left: 1rem;
    height: 2rem;
  }

  .gate-input-area {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding: $seven 0;
    border: $one solid #ced4da;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    appearance: none;
    border-radius: $six;
    max-height: 16rem;
    overflow-y: auto;
    padding: $seven;
  }

  .gate-input-box {
    min-width: 4rem;
    flex: 1;
    border: 0;

    &:focus {
      outline: none;
      border: 0;
    }
  }

  .gate-input-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
    border-color: #007bff;
  }
}

.remove-station-button {
  background: none;
  color: white;
  border: none;
  padding: 0;
  cursor: pointer;
}

.display-station-tag-text {
  flex: 1;
  text-align: center;
}

.add-station-button {
  background-color: $aa-light-blue;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: $thirty-six;
  width: $thirty-six;
}

.additional-stations {
  font-size: 1.5rem;
}

.stations-footer-button {
  width: 8rem;
}

.cancel-button {
  .p-button {
    color: $aa-black;
    background: $aa-ultra-light-gray;
    border-color: $aa-ultra-light-gray;
  }
}

.stations-modal-selected-stations {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0.5rem 0.75rem;
  min-height: $fourty-two;
  max-height: $hundred;
  overflow-y: auto;
}

.stations-modal-selected-stations.regions-border.aa-mb-0 {
  margin-bottom: 0rem;
}

.stations-modal {
  display: flex;
  vertical-align: middle;
  flex-direction: column;
}

.regions-border {
  border: solid $one #ced4da;
  border-radius: 0.25rem;
}

.regions-stations-menu {
  flex: 1;
  overflow-y: auto;
}

.regions-lhs {
  max-height: $four-hundred-seventy-six;
}

.regions-menu-item {
  border-bottom: solid $one #ced4da;
}

.regions-select-all-bg {
  background-color: lightgray;
}

.light-blue-regions {
  background-color: $aa-highlight-blue;
  color: $aa-bright-blue;
}

.blue-station {
  color: $aa-bright-blue;
}

.green-station {
  color: green;
}

.station-search-icon {
  width: 100%;
}

.station-search-input {
  width: 100%;
  height: 100%;
  border: none;
  font-size: 16px;
  padding-left: 1rem;
}

.station-search-input:focus {
  box-shadow: inset 0 0 0 00.2rem rgba(38, 143, 255, 0.5);
  outline: none;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  text-transform: none;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  text-transform: none;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  text-transform: none;
}

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  text-transform: none;
}

::placeholder {
  /* Recent browsers */
  text-transform: none;
}

/*#endregion******** CC VIEW HEADER (CCH)*******/

/*#region******** CC VIEW DETAIL/TABLE (CC)*******/

.ground-time {
  color: $aa-black;
}

.ground-time.D {
  color: $aa-dark-red;
}

.ground-time.W {
  color: $aa-light-orange;
}

.ground-time.S {
  color: $aa-green;
}

.delay-alert-modal {
  max-width: 50%;

  .bg-acquamarine {
    background-color: aquamarine;
  }

  .bg-light-sky-blue {
    background-color: lightskyblue;
  }
}

.S,
.P {
  color: $aa-medium-dark-gray;
}

.T,
.I,
.O {
  color: $aa-green;
}

.E,
.C,
.H,
.A,
.N {
  color: $aa-white;
  padding: $three;
  background-color: $aa-dark-red;
}

.F {
  color: $aa-light-orange;
  padding: $three;
}

.G,
.AI,
.A {
  background: $aa-dark-red;
  color: $aa-white;
  padding: $three;
}

.cc-item {
  cursor: pointer;
  border-left: $five solid transparent;

  .aa,
  .AA {
    background-image: url('/content/images/aa-icon.png');
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: $three;
    padding-right: $fifteen;
  }

  .us,
  .US {
    background-image: url('/content/images/us-icon.png');
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: $three;
    padding-right: $fifteen;
  }

  .h3-1 {
    display: block;
    margin: 0;
  }

  .h3-2 {
    font-size: $eighteen;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
    padding: $three;
  }
}

.cc-italic {
  font: italic bold 15px/20px Georgia, serif;
  align-content: center;
  pointer-events: none;
}

.cc-resizer {
  position: fixed;
  float: right;
  z-index: 1001;
  bottom: 0;
  top: $fifty-one;
  cursor: e-resize;
  font: normal normal normal calc($fourteen/1) FontAwesome;
  box-shadow: (-$ten) 0 $fifteen - ($four) #888;
  width: $ten;
  background-color: #fff;
}

.aa-column-header {
  color: $aa-light-blue;
  cursor: pointer;
  text-transform: uppercase;
  margin-left: 0.5rem;
}

.aa-column-header.active {
  color: $aa-bright-blue;
}

.create-new-task {
  float: left;
  color: $aa-bright-blue;
  font-size: $seventeen;
}

.ots-stop-sign {
  position: relative;
  width: $twenty-one;
  height: $nineteen;
  background-image: url('./assets/images/aos-icon.svg');
  background-repeat: no-repeat;
  background-position: left;
  display: block;
  margin: $three;
  float: left;
  background-size: contain;
}

.maintenance-hold-sign {
  width: $twenty-one;
  height: $nineteen;
  background-image: url('./assets/images/lock.svg');
  position: relative;
  background-repeat: no-repeat;
  background-position: left;
  display: block;
  float: left;
  color: $aa-dark-red;
  margin: 2%;
}

.cc-right-panel-style {
  padding-bottom: 3rem !important;
  height: calc(100vh - $six);
  overflow: auto;
  background-color: $aa-white;
  z-index: 100;
}

.cc-left-panel {
  float: left;
  padding: 0 $one;
}

.cc-right-panel {
  float: left;
}

.cc-hr {
  margin-top: $one;
  margin-bottom: $one;
  border: 0;
  border-top: $one solid $aa-bright-blue;
}

.cc-hr-gray {
  margin-top: 0.5rem;
  color: $aa-gray;
}

.cc-feedback-icon {
  content: url('./assets/images/global-header/feedback-icon.svg');
}

.cc-help-icon {
  content: url('./assets/images/global-header/help-icon.svg');
}

.cc-logout-icon {
  content: url('./assets/images/global-header/logout-icon.svg');
}

.cc-logo-icon {
  content: url('./assets/images/global-header/aa-lmo-logo.svg');
}

.cc-table {
  padding-top: $three;
  overflow-x: hidden;
  border-left: $one solid $aa-medium-dark-gray;
  border-right: $one solid $aa-medium-dark-gray;
  height: calc(100vh - $hundred-ten);
}

.cc-no-result {
  font-size: $eighteen;
  margin: $twenty-five $ten;
}

.h3-1 {
  font-size: $twenty-one;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: $aa-shade-black;
}

.history-table {
  .p-datatable {
    .p-datatable-thead > tr > th {
      font-weight: 200;
      color: #76b3f6;
      background: $aa-shade-gray;
      transition: box-shadow 0.15s;
      box-shadow: inset 0 (-$one) #d5d5d5;
    }

    tbody {
      tr {
        td {
          &.discrepancy-column-data {
            width: 18%;
          }
        }
      }
    }
  }
}

.scrollArea {
  // height: 100%;
  overflow-x: auto;
  overflow-y: auto;

  .p-datatable {
    .p-datatable-thead > tr > th {
      padding: 0 $five;
      border-top: 0;
      font-weight: 300;
      color: $aa-light-blue;
      background: $aa-shade-gray;
      transition: box-shadow 0.15s;
      box-shadow: inset 0 (-$one) #d5d5d5;

      &.aa-pl-8 {
        padding-left: 2rem;
      }
    }

    .p-datatable-tbody > tr {
      background: #f8f8f8;

      &:hover {
        background-color: #efefef;
        border-left-color: #ccc;
      }

      &.active {
        background-color: $light-blue-hover;
        border-left-color: #48c;
        cursor: default;
      }

      &.delay-flight-with-task {
        background-color: $light-pink;
      }

      td {
        border-top: $one solid #ddd;
        padding: $five;
      }

      .aa-pl-8 {
        padding-left: 2rem;
      }
    }
  }

  table {
    margin-bottom: 0;
    width: 100%;

    thead {
      tr {
        display: grid;
        grid-template-columns: $hundred-thirty $hundred-ten $ninety $hundred $hundred-ten $hundred-ten 1fr 1fr 1fr;

        th {
          padding: 0;
          min-width: $fourty;
          border-top: $one solid #d5d5d5;
          padding: $thirty;
        }
      }
    }

    .th-inner {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      top: 0;
      height: $thirty-six;
      line-height: $thirty-six;
      padding-right: $twenty;

      .ng-scope {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.condensed {
        padding: 0 $three;
      }
    }

    tbody tr {
      display: grid;
      grid-template-columns: $hundred-thirty $hundred-ten $ninety $hundred $hundred-ten $hundred-ten 1fr 1fr 1fr;

      td {
        overflow: hidden;
        text-overflow: ellipsis;

        &.padding {
          padding-left: 1rem;
        }

        &.padding-lite {
          padding-left: $eight;
          border-top: $one;
        }

        &:first-child {
          border-left: none;
        }

        &:last-child {
          border-right: none;
        }
      }
    }
  }
}

span > strong {
  display: inline-block;
}

/*#endregion******** CC VIEW DETAIL/TABLE (CC)*******/

/*#region******** CC VIEW AIRCRAFT ATTRIBUTE (CCA)*******/
.ac-attr-container {
  overflow-x: hidden;
  padding-right: $twelve;
  padding-left: $twelve;
  padding-top: $eight;

  .table {
    th {
      padding: $five;
      border-top: none;
    }

    td {
      padding: $five;

      .table {
        td {
          padding: $zero;
          border-top: none;
        }
      }
    }
  }

  .cross {
    height: $fifty;
  }

  .attr .h3 {
    font-size: $eighteen;
  }
}

.ac-attr-header {
  padding-left: 0;
  padding-right: 0;
  float: none;
}

.priority-width {
  min-width: $sixty;
}

/*#endregion******** CC VIEW AIRCRAFT ATTRIBUTE (CCA)*******/

/*#region******** CC VIEW RHS Task ATTRIBUTE (CCA)*******/
.rhs-tile-format {
  box-sizing: border-box;
  display: block;
  height: 1.8rem;
  text-align: center;
}

.cc-detail-task {
  border: none !important;
  padding: $ten;
  background: #ebeef0;
  box-shadow: inset $zero $one $five 0 rgba(0, 0, 0, 0.26);
  border-radius: $five;
  border: none;
}

.cc-detail-task .header.header-default {
  background-color: #d0dae0;
  box-shadow: $zero $one $five 0 rgba(0, 0, 0, 0.26);
  border: none;
}

.cc-task-history {
  padding-top: 0.01rem;
  padding-bottom: 0.01rem;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
  font-size: 0.8rem;
}

.cc-td-def-reason {
  padding-right: 0px;
  min-width: $two-hundred-twenty;
}

/*#endregion******** CC VIEW AIRCRAFT ATTRIBUTE (CCA)*******/

/*#region******** CC VIEW FILTER (CCA)*******/
.filter-footer {
  background-color: $aa-shade-gray;
  border-color: #e7e7e7;
  height: $fifty;
}

.sub-heading {
  padding: 0.5rem 1.5rem;
  border-bottom: 0.1rem solid transparent;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  font-size: 1.2rem;
  background-color: #ccc;
  color: $aa-shade-black;
  margin: 0.5rem;
}

.filter-radio-val {
  margin: 0 0 0.25rem 0.5rem;
}

/*#endregion******** CC VIEW FILTER (CCA)*******/

/*#region******** CC ASSIGN TO DROPDOWN *******/

.assign-to-table-column-header {
  color: #337ab7;
}

.border-none {
  border: none;
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.assign-td {
  box-sizing: border-box;
  vertical-align: text-top;
}

.assign-to {
  .tick {
    min-width: 2rem;
  }

  .assign-dd-table {
    border: $one solid $aa-light-gray;
    border-radius: $six;
  }

  tr.bg-light-blue {
    @extend .bg-light-blue;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background: transparent;
  }
  .p-dropdown-panel .p-dropdown-header {
    padding: 0.25rem 0.5rem;
  }
  .assign-to-search {
    border: $one solid $aa-medium-gray;
    width: 100%;
    padding-left: 0.5rem;
  }
  table {
    margin-bottom: 0;
  }

  .table td,
  .table th {
    vertical-align: middle;
  }

  .p-datatable.p-datatable-sm {
    .p-datatable-thead > tr > th {
      padding: $four;
    }

    .p-datatable-tbody > tr > td {
      padding: $four;
    }
  }

  .p-dropdown .p-dropdown-label.p-placeholder {
    color: inherit;
  }
}

/*#endregion******** CC ASSIGN TO DROPDOWN *******/

/*#region******** NEW MANUAL TASK (NT) *******/
.nt-radius {
  border-bottom-right-radius: $two;
  border-bottom-left-radius: $two;
}

.nt-find-nn {
  height: $thirty-two;
  margin-block: $ten;
}

.auto-height {
  height: auto;
}

.dropdown-task {
  min-width: $hundred;
  margin: $two 0 0;
  font-size: $fourteen;
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border: $one solid rgba(0, 0, 0, 0.15);
  border-radius: $four;
  box-shadow: 0 $six $twelve rgba(0, 0, 0, 0.175);

  .p-dropdown-items .p-dropdown-item {
    margin: 0;
    padding: 0 $four;
    border: 0 none;
    color: #212529;
    background: transparent;
    transition: box-shadow 0.15s;
    border-radius: 0;
    text-wrap: wrap;
  }
}

.assign {
  .p-datatable {
    .p-datatable-thead > tr > th {
      padding: $one;
      font-weight: 400;
      transition: box-shadow 0.15s;
      border-width: $one;
      color: #337ab7;
    }

    .p-datatable-tbody {
      tr > td {
        text-align: left;
        border: $one solid #dee2e6;
        border-width: $one;
        padding: $two;
        text-wrap: wrap;
      }

      tr.bg-light-blue {
        @extend .bg-light-blue;
      }
    }
  }

  .p-multiselect-panel {
    .p-multiselect-header {
      display: none;
    }

    .p-multiselect-items {
      padding: 0;

      .p-multiselect-item {
        padding: $four;

        .p-checkbox {
          display: none;
        }

        p-table {
          width: 100%;
        }
      }

      .p-multiselect-item.p-highlight {
        color: #ffffff;
        background: transparent;
      }
    }
  }
}

.assigned {
  .p-datatable .p-datatable-tbody > tr > td {
    padding: $five;
  }
}

/*#endregion******** NEW MANUAL TASK (NT) *******/

/*#region******** NOTIFICATIONS (NOTS) *******/
.maint-item {
  background-color: #000;
  color: #fff;
  padding: $three;
  white-space: nowrap;
  position: relative;
}

.manual {
  color: #131313 !important;
  border-radius: $three !important;
  background: #e1f0fa !important;
  background: -webkit-linear-gradient(#e1f0fa, #bedef4) !important;
  background: -o-linear-gradient(#e1f0fa, #bedef4) !important;
  background: -moz-linear-gradient(#e1f0fa, #bedef4) !important;
  background: linear-gradient(#e1f0fa, #bedef4) !important;
  border: none !important;
}

.manualtask {
  color: #ffffff !important;
  border-radius: $three !important;
  background: #3355fd !important;
  background: -webkit-linear-gradient(#3355fd, #3355fd) !important;
  background: -o-linear-gradient(#3355fd, #3355fd) !important;
  background: -moz-linear-gradient(#3355fd, #3355fd) !important;
  background: linear-gradient(#3355fd, #3355fd) !important;
  border: none !important;
}

.maint-task.bowtask {
  color: #004da0;
  border-radius: $three;
  background: #fff;
  border: 0.2rem solid;
  padding: 0.5px 3px;
  border-color: #004da0;
}

.manualcubatask {
  font-weight: bold;
  border-radius: $three;
  color: $aa-white;
  background-color: #002590;
}

.cuba-flag-svg-icon {
  content: url('./assets/images/cuba-flag-icon.svg');
  display: inline-block;
  width: 2rem;
  vertical-align: middle;
}

.default,
.deferral {
  color: #fff !important;
  border-radius: $three !important;
  background: #000000 !important;
  background: -webkit-linear-gradient(#1a1a1a, #000000) !important;
  background: -o-linear-gradient(#1a1a1a, #000000) !important;
  background: -moz-linear-gradient(#1a1a1a, #000000) !important;
  background: linear-gradient(#1a1a1a, #000000) !important;
  border: none !important;
}

.arms,
.hermes {
  color: #131313 !important;
  border-radius: $three !important;
  background: #faaf00 !important;
  background: -webkit-linear-gradient(#faaf00, #cc8f00) !important;
  background: -o-linear-gradient(#faaf00, #cc8f00) !important;
  background: -moz-linear-gradient(#faaf00, #cc8f00) !important;
  background: linear-gradient(#faaf00, #cc8f00) !important;
  border: none !important;
}

.qfx {
  color: #fff !important;
  border-radius: $three !important;
  background: #5cb85c !important;
  background: -webkit-linear-gradient(#5cb85c, #46a046) !important;
  background: -o-linear-gradient(#5cb85c, #46a046) !important;
  background: -moz-linear-gradient(#5cb85c, #46a046) !important;
  background: linear-gradient(#5cb85c, #46a046) !important;
  border: none !important;
}

.qdaily {
  color: #fff !important;
  border-radius: $three !important;
  background: #5cb85c !important;
  background: -webkit-linear-gradient(#5cb85c, #46a046) !important;
  background: -o-linear-gradient(#5cb85c, #46a046) !important;
  background: -moz-linear-gradient(#5cb85c, #46a046) !important;
  background: linear-gradient(#5cb85c, #46a046) !important;
  border: none !important;
}

.oil {
  color: #ffffff !important;
  border-radius: $three !important;
  background: #8f7099 !important;
  background: -webkit-linear-gradient(#8f7099, #6f5577) !important;
  background: -o-linear-gradient(#8f7099, #6f5577) !important;
  background: -moz-linear-gradient(#8f7099, #6f5577) !important;
  background: linear-gradient(#8f7099, #6f5577) !important;
  border: none !important;
}

.osp-oil {
  color: #131313 !important;
  border-radius: $three !important;
  background: #fbd06b !important;
  border: none !important;
}

.qcycle {
  color: #f52305 !important;
  border-radius: $three !important;
  background: #d1d532 !important;
  background: -webkit-linear-gradient(#bcbe27, #bcbe27) !important;
  background: -o-linear-gradient(#d1d532, #bcbe27) !important;
  background: -moz-linear-gradient(#d1d532, #bcbe27) !important;
  background: linear-gradient(#d1d532, #bcbe27) !important;
  border: none !important;
}

.nfnf {
  color: #fff !important;
  border-radius: $three !important;
  background: #f52305 !important;
  background: -webkit-linear-gradient(#f52305, #c81e04) !important;
  background: -o-linear-gradient(#f52305, #c81e04) !important;
  background: -moz-linear-gradient(#f52305, #c81e04) !important;
  background: linear-gradient(#f52305, #c81e04) !important;
  border: none !important;
}

.daily {
  color: #fff !important;
  border-radius: $three !important;
  background: #4db4fa !important;
  background: -webkit-linear-gradient(#4db4fa, #1fa2f9) !important;
  background: -o-linear-gradient(#4db4fa, #1fa2f9) !important;
  background: -moz-linear-gradient(#4db4fa, #1fa2f9) !important;
  background: linear-gradient(#4db4fa, #1fa2f9) !important;
  border: none !important;
}

.cycle {
  color: #f52305 !important;
  border-radius: $three !important;
  background: #d1d532 !important;
  background: -webkit-linear-gradient(#d1d532, #bcbe27) !important;
  background: -o-linear-gradient(#d1d532, #bcbe27) !important;
  background: -moz-linear-gradient(#d1d532, #bcbe27) !important;
  background: linear-gradient(#d1d532, #bcbe27) !important;
  border: none !important;
}

.wkup {
  color: #000 !important;
  border-radius: $three !important;
  background: #d0dae0 !important;
  background: -webkit-linear-gradient(#d0dae0, #b2c2cd) !important;
  background: -o-linear-gradient(#d0dae0, #b2c2cd) !important;
  background: -moz-linear-gradient(#d0dae0, #b2c2cd) !important;
  background: linear-gradient(#d0dae0, #b2c2cd) !important;
  border: none !important;
}

.rswk {
  color: #000 !important;
  border-radius: $three !important;
  background: #d0dae0 !important;
  background: -webkit-linear-gradient(#d0dae0, #b2c2cd) !important;
  background: -o-linear-gradient(#d0dae0, #b2c2cd) !important;
  background: -moz-linear-gradient(#d0dae0, #b2c2cd) !important;
  background: linear-gradient(#d0dae0, #b2c2cd) !important;
  border: none !important;
}

.term {
  color: #000 !important;
  border-radius: $three !important;
  background: #d0dae0 !important;
  background: -webkit-linear-gradient(#d0dae0, #b2c2cd) !important;
  background: -o-linear-gradient(#d0dae0, #b2c2cd) !important;
  background: -moz-linear-gradient(#d0dae0, #b2c2cd) !important;
  background: linear-gradient(#d0dae0, #b2c2cd) !important;
  border: none !important;
}

.rsmg {
  color: #000 !important;
  border-radius: $three !important;
  background: #d0dae0 !important;
  background: -webkit-linear-gradient(#d0dae0, #b2c2cd) !important;
  background: -o-linear-gradient(#d0dae0, #b2c2cd) !important;
  background: -moz-linear-gradient(#d0dae0, #b2c2cd) !important;
  background: linear-gradient(#d0dae0, #b2c2cd) !important;
  border: none !important;
}

.mg {
  color: #000 !important;
  border-radius: $three !important;
  background: #d0dae0 !important;
  background: -webkit-linear-gradient(#d0dae0, #b2c2cd) !important;
  background: -o-linear-gradient(#d0dae0, #b2c2cd) !important;
  background: -moz-linear-gradient(#d0dae0, #b2c2cd) !important;
  background: linear-gradient(#d0dae0, #b2c2cd) !important;
  border: none !important;
}

.tirechk {
  color: #000 !important;
  border-radius: $three !important;
  background: #d0dae0 !important;
  background: -webkit-linear-gradient(#d0dae0, #b2c2cd) !important;
  background: -o-linear-gradient(#d0dae0, #b2c2cd) !important;
  background: -moz-linear-gradient(#d0dae0, #b2c2cd) !important;
  background: linear-gradient(#d0dae0, #b2c2cd) !important;
  border: none !important;
}

.transchk {
  color: #000 !important;
  border-radius: $three !important;
  background: #d0dae0 !important;
  background: -webkit-linear-gradient(#d0dae0, #b2c2cd) !important;
  background: -o-linear-gradient(#d0dae0, #b2c2cd) !important;
  background: -moz-linear-gradient(#d0dae0, #b2c2cd) !important;
  background: linear-gradient(#d0dae0, #b2c2cd) !important;
  border: none !important;
}

.rightstart {
  color: #f52305 !important;
  border-radius: $three !important;
  background: #d0dae0 !important;
  background: -webkit-linear-gradient(#d0dae0, #b2c2cd) !important;
  background: -o-linear-gradient(#d0dae0, #b2c2cd) !important;
  background: -moz-linear-gradient(#d0dae0, #b2c2cd) !important;
  background: linear-gradient(#d0dae0, #b2c2cd) !important;
  border: none !important;
}

.melr {
  color: #fff !important;
  border-radius: $three !important;
  background: #000000 !important;
  background: -webkit-linear-gradient(#1a1a1a, #000000) !important;
  background: -o-linear-gradient(#1a1a1a, #000000) !important;
  background: -moz-linear-gradient(#1a1a1a, #000000) !important;
  background: linear-gradient(#1a1a1a, #000000) !important;
  border: none !important;
}

.melci {
  color: #fff !important;
  border-radius: $three !important;
  background: #000000 !important;
  background: -webkit-linear-gradient(#1a1a1a, #000000) !important;
  background: -o-linear-gradient(#1a1a1a, #000000) !important;
  background: -moz-linear-gradient(#1a1a1a, #000000) !important;
  background: linear-gradient(#1a1a1a, #000000) !important;
  border: none !important;
}

.melf {
  color: #f52305 !important;
  border-radius: $three !important;
  background: #d1d532 !important;
  background: -webkit-linear-gradient(#dadc56, #d1d532) !important;
  background: -o-linear-gradient(#dadc56, #d1d532) !important;
  background: -moz-linear-gradient(#dadc56, #d1d532) !important;
  background: linear-gradient(#dadc56, #d1d532) !important;
  border: none !important;
}

.melfr {
  color: #f52305 !important;
  border-radius: $three !important;
  background: #d1d532 !important;
  background: -webkit-linear-gradient(#dadc56, #d1d532) !important;
  background: -o-linear-gradient(#dadc56, #d1d532) !important;
  background: -moz-linear-gradient(#dadc56, #d1d532) !important;
  background: linear-gradient(#dadc56, #d1d532) !important;
  border: none !important;
}

.melfci {
  color: #f52305 !important;
  border-radius: $three !important;
  background: #d1d532 !important;
  background: -webkit-linear-gradient(#dadc56, #d1d532) !important;
  background: -o-linear-gradient(#dadc56, #d1d532) !important;
  background: -moz-linear-gradient(#dadc56, #d1d532) !important;
  background: linear-gradient(#dadc56, #d1d532) !important;
  border: none !important;
}

.meld {
  color: #fff !important;
  border-radius: $three !important;
  background: #fb934a !important;
  background: -webkit-linear-gradient(#fb934a, #fa7a1e) !important;
  background: -o-linear-gradient(#fb934a, #fa7a1e) !important;
  background: -moz-linear-gradient(#fb934a, #fa7a1e) !important;
  background: linear-gradient(#fb934a, #fa7a1e) !important;
  border: none !important;
}

.meldr {
  color: #fff !important;
  border-radius: $three !important;
  background: #fb934a !important;
  background: -webkit-linear-gradient(#fb934a, #fa7a1e) !important;
  background: -o-linear-gradient(#fb934a, #fa7a1e) !important;
  background: -moz-linear-gradient(#fb934a, #fa7a1e) !important;
  background: linear-gradient(#fb934a, #fa7a1e) !important;
  border: none !important;
}

.meldci {
  color: #fff !important;
  border-radius: $three !important;
  background: #fb934a !important;
  background: -webkit-linear-gradient(#fb934a, #fa7a1e) !important;
  background: -o-linear-gradient(#fb934a, #fa7a1e) !important;
  background: -moz-linear-gradient(#fb934a, #fa7a1e) !important;
  background: linear-gradient(#fb934a, #fa7a1e) !important;
  border: none !important;
}

.melq {
  color: #fff !important;
  border-radius: $three !important;
  background: #5cb85c !important;
  background: -webkit-linear-gradient(#5cb85c, #46a046) !important;
  background: -o-linear-gradient(#5cb85c, #46a046) !important;
  background: -moz-linear-gradient(#5cb85c, #46a046) !important;
  background: linear-gradient(#5cb85c, #46a046) !important;
  border: none !important;
}

.melqr {
  color: #fff !important;
  border-radius: $three !important;
  background: #5cb85c !important;
  background: -webkit-linear-gradient(#5cb85c, #46a046) !important;
  background: -o-linear-gradient(#5cb85c, #46a046) !important;
  background: -moz-linear-gradient(#5cb85c, #46a046) !important;
  background: linear-gradient(#5cb85c, #46a046) !important;
  border: none !important;
}

.tii {
  color: black !important;
  border-radius: $three !important;
  background: #dadc56 !important;
  background: -webkit-linear-gradient(#dadc56, #d1d532) !important;
  background: -o-linear-gradient(#dadc56, #d1d532) !important;
  background: -moz-linear-gradient(#dadc56, #d1d532) !important;
  background: linear-gradient(#dadc56, #d1d532) !important;
  border: none !important;
}

.mon {
  color: black !important;
  border-radius: $three !important;
  background: #dadc56 !important;
  background: -webkit-linear-gradient(#dadc56, #d1d532) !important;
  background: -o-linear-gradient(#dadc56, #d1d532) !important;
  background: -moz-linear-gradient(#dadc56, #d1d532) !important;
  background: linear-gradient(#dadc56, #d1d532) !important;
  border: none !important;
}

.ibgatemanning {
  color: white !important;
  border-radius: $three !important;
  background: purple !important;
  background: -webkit-linear-gradient(purple, purple) !important;
  background: -o-linear-gradient(purple, purple) !important;
  background: -moz-linear-gradient(purple, purple) !important;
  background: linear-gradient(purple, purple) !important;
  border: none !important;
}

.obgatemanning {
  color: #05f5ea !important;
  border-radius: $three !important;
  background: purple !important;
  background: -webkit-linear-gradient(purple, purple) !important;
  background: -o-linear-gradient(purple, purple) !important;
  background: -moz-linear-gradient(purple, purple) !important;
  background: linear-gradient(purple, purple) !important;
  border: none !important;
}

.rob,
.ops,
.rem {
  color: #fff !important;
  border-radius: $three !important;
  background: #f52305 !important;
  background: -webkit-linear-gradient(#f52305, #c81e04) !important;
  background: -o-linear-gradient(#f52305, #c81e04) !important;
  background: -moz-linear-gradient(#f52305, #c81e04) !important;
  background: linear-gradient(#f52305, #c81e04) !important;
  border: none !important;
}

.melqci {
  color: #fff !important;
  border-radius: $three !important;
  background: #5cb85c !important;
  background: -webkit-linear-gradient(#5cb85c, #46a046) !important;
  background: -o-linear-gradient(#5cb85c, #46a046) !important;
  background: -moz-linear-gradient(#5cb85c, #46a046) !important;
  background: linear-gradient(#5cb85c, #46a046) !important;
  border: none !important;
}

.melqf {
  color: #f52305 !important;
  border-radius: $three !important;
  background: #d1d532 !important;
  background: -webkit-linear-gradient(#dadc56, #d1d532) !important;
  background: -o-linear-gradient(#dadc56, #d1d532) !important;
  background: -moz-linear-gradient(#dadc56, #d1d532) !important;
  background: linear-gradient(#dadc56, #d1d532) !important;
  border: none !important;
}

.melqfri {
  color: #f52305 !important;
  border-radius: $three !important;
  background: #d1d532 !important;
  background: -webkit-linear-gradient(#dadc56, #d1d532) !important;
  background: -o-linear-gradient(#dadc56, #d1d532) !important;
  background: -moz-linear-gradient(#dadc56, #d1d532) !important;
  background: linear-gradient(#dadc56, #d1d532) !important;
  border: none !important;
}

.melqfci {
  color: #f52305 !important;
  border-radius: $three !important;
  background: #d1d532 !important;
  background: -webkit-linear-gradient(#dadc56, #d1d532) !important;
  background: -o-linear-gradient(#dadc56, #d1d532) !important;
  background: -moz-linear-gradient(#dadc56, #d1d532) !important;
  background: linear-gradient(#dadc56, #d1d532) !important;
  border: none !important;
}

.melqd {
  color: #fff !important;
  border-radius: $three !important;
  background: #fb934a !important;
  background: -webkit-linear-gradient(#fb934a, #fa7a1e) !important;
  background: -o-linear-gradient(#fb934a, #fa7a1e) !important;
  background: -moz-linear-gradient(#fb934a, #fa7a1e) !important;
  background: linear-gradient(#fb934a, #fa7a1e) !important;
  border: none !important;
}

.melqdr {
  color: #fff !important;
  border-radius: $three !important;
  background: #fb934a !important;
  background: -webkit-linear-gradient(#fb934a, #fa7a1e) !important;
  background: -o-linear-gradient(#fb934a, #fa7a1e) !important;
  background: -moz-linear-gradient(#fb934a, #fa7a1e) !important;
  background: linear-gradient(#fb934a, #fa7a1e) !important;
  border: none !important;
}

.melqcid {
  color: #fff !important;
  border-radius: $three !important;
  background: #fb934a !important;
  background: -webkit-linear-gradient(#fb934a, #fa7a1e) !important;
  background: -o-linear-gradient(#fb934a, #fa7a1e) !important;
  background: -moz-linear-gradient(#fb934a, #fa7a1e) !important;
  background: linear-gradient(#fb934a, #fa7a1e) !important;
  border: none !important;
}

.melCycle {
  color: #f52305 !important;
  border-radius: $three !important;
  background: #fff !important;
  background: -webkit-linear-gradient(#fff, #fff) !important;
  background: -o-linear-gradient(#fff, #fff) !important;
  background: -moz-linear-gradient(#fff, #fff) !important;
  background: linear-gradient(#fff, #fff) !important;
  border: 0.2rem solid !important;
  border-color: red !important;
}

.airworthiness {
  color: $aa-black-90 !important;
  border-radius: $three !important;
  background: $aa-light-teal !important;
  border: none !important;
  cursor: default;
}

/*#endregion******** NOTIFICATIONS (NOTS) *******/

/*#region******** TASK (T) *******/
.maint-task {
  padding: 3px;
  white-space: nowrap;
  position: relative;
}

.qfxTask {
  color: #fff !important;
  border-radius: $three !important;
  background: #5cb85c !important;
  background: -webkit-linear-gradient(#5cb85c, #46a046) !important;
  background: -o-linear-gradient(#5cb85c, #46a046) !important;
  background: -moz-linear-gradient(#5cb85c, #46a046) !important;
  background: linear-gradient(#5cb85c, #46a046) !important;
  border: none !important;
}

.qdailyTask {
  color: #fff !important;
  border-radius: $three !important;
  background: #5cb85c !important;
  background: -webkit-linear-gradient(#5cb85c, #46a046) !important;
  background: -o-linear-gradient(#5cb85c, #46a046) !important;
  background: -moz-linear-gradient(#5cb85c, #46a046) !important;
  background: linear-gradient(#5cb85c, #46a046) !important;
  border: none !important;
}

.qcycleTask {
  color: #f52305 !important;
  border-radius: $three !important;
  background: #d1d532 !important;
  background: -webkit-linear-gradient(#bcbe27, #bcbe27) !important;
  background: -o-linear-gradient(#d1d532, #bcbe27) !important;
  background: -moz-linear-gradient(#d1d532, #bcbe27) !important;
  background: linear-gradient(#d1d532, #bcbe27) !important;
  border: none !important;
}

.dailyTask {
  color: #fff !important;
  border-radius: $three !important;
  background: #4db4fa !important;
  background: -webkit-linear-gradient(#4db4fa, #1fa2f9) !important;
  background: -o-linear-gradient(#4db4fa, #1fa2f9) !important;
  background: -moz-linear-gradient(#4db4fa, #1fa2f9) !important;
  background: linear-gradient(#4db4fa, #1fa2f9) !important;
  border: none !important;
}

.cycleTask {
  color: #f52305 !important;
  border-radius: $three !important;
  background: #d1d532 !important;
  background: -webkit-linear-gradient(#d1d532, #bcbe27) !important;
  background: -o-linear-gradient(#d1d532, #bcbe27) !important;
  background: -moz-linear-gradient(#d1d532, #bcbe27) !important;
  background: linear-gradient(#d1d532, #bcbe27) !important;
  border: none !important;
}

.melrTask {
  color: #fff !important;
  border-radius: $three !important;
  background: #000000 !important;
  background: -webkit-linear-gradient(#1a1a1a, #000000) !important;
  background: -o-linear-gradient(#1a1a1a, #000000) !important;
  background: -moz-linear-gradient(#1a1a1a, #000000) !important;
  background: linear-gradient(#1a1a1a, #000000) !important;
  border: none !important;
}

.melciTask {
  color: #fff !important;
  border-radius: $three !important;
  background: #000000 !important;
  background: -webkit-linear-gradient(#1a1a1a, #000000) !important;
  background: -o-linear-gradient(#1a1a1a, #000000) !important;
  background: -moz-linear-gradient(#1a1a1a, #000000) !important;
  background: linear-gradient(#1a1a1a, #000000) !important;
  border: none !important;
}

.melfTask {
  color: #f52305 !important;
  border-radius: $three !important;
  background: #d1d532 !important;
  background: -webkit-linear-gradient(#dadc56, #d1d532) !important;
  background: -o-linear-gradient(#dadc56, #d1d532) !important;
  background: -moz-linear-gradient(#dadc56, #d1d532) !important;
  background: linear-gradient(#dadc56, #d1d532) !important;
  border: none !important;
}

.melfrTask {
  color: #f52305 !important;
  border-radius: $three !important;
  background: #d1d532 !important;
  background: -webkit-linear-gradient(#dadc56, #d1d532) !important;
  background: -o-linear-gradient(#dadc56, #d1d532) !important;
  background: -moz-linear-gradient(#dadc56, #d1d532) !important;
  background: linear-gradient(#dadc56, #d1d532) !important;
  border: none !important;
}

.melfciTask {
  color: #f52305 !important;
  border-radius: $three !important;
  background: #d1d532 !important;
  background: -webkit-linear-gradient(#dadc56, #d1d532) !important;
  background: -o-linear-gradient(#dadc56, #d1d532) !important;
  background: -moz-linear-gradient(#dadc56, #d1d532) !important;
  background: linear-gradient(#dadc56, #d1d532) !important;
  border: none !important;
}

.meldTask {
  color: #fff !important;
  border-radius: $three !important;
  background: #fb934a !important;
  background: -webkit-linear-gradient(#fb934a, #fa7a1e) !important;
  background: -o-linear-gradient(#fb934a, #fa7a1e) !important;
  background: -moz-linear-gradient(#fb934a, #fa7a1e) !important;
  background: linear-gradient(#fb934a, #fa7a1e) !important;
  border: none !important;
}

.meldrTask {
  color: #fff !important;
  border-radius: $three !important;
  background: #fb934a !important;
  background: -webkit-linear-gradient(#fb934a, #fa7a1e) !important;
  background: -o-linear-gradient(#fb934a, #fa7a1e) !important;
  background: -moz-linear-gradient(#fb934a, #fa7a1e) !important;
  background: linear-gradient(#fb934a, #fa7a1e) !important;
  border: none !important;
}

.meldciTask {
  color: #fff !important;
  border-radius: $three !important;
  background: #fb934a !important;
  background: -webkit-linear-gradient(#fb934a, #fa7a1e) !important;
  background: -o-linear-gradient(#fb934a, #fa7a1e) !important;
  background: -moz-linear-gradient(#fb934a, #fa7a1e) !important;
  background: linear-gradient(#fb934a, #fa7a1e) !important;
  border: none !important;
}

.melqTask {
  color: #fff !important;
  border-radius: $three !important;
  background: #5cb85c !important;
  background: -webkit-linear-gradient(#5cb85c, #46a046) !important;
  background: -o-linear-gradient(#5cb85c, #46a046) !important;
  background: -moz-linear-gradient(#5cb85c, #46a046) !important;
  background: linear-gradient(#5cb85c, #46a046) !important;
  border: none !important;
}

.melqrTask {
  color: #fff !important;
  border-radius: $three !important;
  background: #5cb85c !important;
  background: -webkit-linear-gradient(#5cb85c, #46a046) !important;
  background: -o-linear-gradient(#5cb85c, #46a046) !important;
  background: -moz-linear-gradient(#5cb85c, #46a046) !important;
  background: linear-gradient(#5cb85c, #46a046) !important;
  border: none !important;
}

.melqciTask {
  color: #fff !important;
  border-radius: $three !important;
  background: #5cb85c !important;
  background: -webkit-linear-gradient(#5cb85c, #46a046) !important;
  background: -o-linear-gradient(#5cb85c, #46a046) !important;
  background: -moz-linear-gradient(#5cb85c, #46a046) !important;
  background: linear-gradient(#5cb85c, #46a046) !important;
  border: none !important;
}

.melqfTask {
  color: #f52305 !important;
  border-radius: $three !important;
  background: #d1d532 !important;
  background: -webkit-linear-gradient(#dadc56, #d1d532) !important;
  background: -o-linear-gradient(#dadc56, #d1d532) !important;
  background: -moz-linear-gradient(#dadc56, #d1d532) !important;
  background: linear-gradient(#dadc56, #d1d532) !important;
  border: none !important;
}

.melqfriTask {
  color: #f52305 !important;
  border-radius: $three !important;
  background: #d1d532 !important;
  background: -webkit-linear-gradient(#dadc56, #d1d532) !important;
  background: -o-linear-gradient(#dadc56, #d1d532) !important;
  background: -moz-linear-gradient(#dadc56, #d1d532) !important;
  background: linear-gradient(#dadc56, #d1d532) !important;
  border: none !important;
}

.melqfciTask {
  color: #f52305 !important;
  border-radius: $three !important;
  background: #d1d532 !important;
  background: -webkit-linear-gradient(#dadc56, #d1d532) !important;
  background: -o-linear-gradient(#dadc56, #d1d532) !important;
  background: -moz-linear-gradient(#dadc56, #d1d532) !important;
  background: linear-gradient(#dadc56, #d1d532) !important;
  border: none !important;
}

.melqdTask {
  color: #fff !important;
  border-radius: $three !important;
  background: #fb934a !important;
  background: -webkit-linear-gradient(#fb934a, #fa7a1e) !important;
  background: -o-linear-gradient(#fb934a, #fa7a1e) !important;
  background: -moz-linear-gradient(#fb934a, #fa7a1e) !important;
  background: linear-gradient(#fb934a, #fa7a1e) !important;
  border: none !important;
}

.melqdrTask {
  color: #fff !important;
  border-radius: $three !important;
  background: #fb934a !important;
  background: -webkit-linear-gradient(#fb934a, #fa7a1e) !important;
  background: -o-linear-gradient(#fb934a, #fa7a1e) !important;
  background: -moz-linear-gradient(#fb934a, #fa7a1e) !important;
  background: linear-gradient(#fb934a, #fa7a1e) !important;
  border: none !important;
}

.melqcidTask {
  color: #fff !important;
  border-radius: $three !important;
  background: #fb934a !important;
  background: -webkit-linear-gradient(#fb934a, #fa7a1e) !important;
  background: -o-linear-gradient(#fb934a, #fa7a1e) !important;
  background: -moz-linear-gradient(#fb934a, #fa7a1e) !important;
  background: linear-gradient(#fb934a, #fa7a1e) !important;
  border: none !important;
}

.melCycle {
  color: #f52305 !important;
  border-radius: $three !important;
  background: #fff !important;
  background: -webkit-linear-gradient(#fff, #fff) !important;
  background: -o-linear-gradient(#fff, #fff) !important;
  background: -moz-linear-gradient(#fff, #fff) !important;
  background: linear-gradient(#fff, #fff) !important;
  border: $two solid !important;
  border-color: red !important;
}

// BASE PRIORITY STYLING

@mixin content-before($prefix) {
  &:before {
    content: $prefix;
  }
}

@mixin cc-task-item-variant($background-color, $text-color) {
  background-color: $background-color;
  border-color: $background-color;
  color: $text-color;
}

.manual {
  @include cc-task-item-variant($aa-teal, $aa-black);
}

.default {
  @include cc-task-item-variant($aa-black, $aa-white);
}

.qfx {
  @include cc-task-item-variant($aa-green, $aa-white);
}

.qdaily {
  @include cc-task-item-variant($aa-orange, $aa-white);
}

.oil {
  @include cc-task-item-variant($aa-yellow-green, $aa-orange);
}

.qcycle {
  @include cc-task-item-variant($aa-yellow-green, $aa-dark-red);
}

.nfnf {
  @include cc-task-item-variant($aa-red, $aa-white);
}

.daily {
  @include cc-task-item-variant($aa-orange, $aa-white);
}

.cycle {
  @include cc-task-item-variant($aa-yellow-green, $aa-dark-red);
}

.wkup {
  @include cc-task-item-variant($aa-blue, $aa-white);
}

.rswk {
  @include cc-task-item-variant($aa-blue, $aa-white);
}

.term {
  @include cc-task-item-variant($aa-light-orange, $aa-black);
}

.rsmg {
  @include cc-task-item-variant($aa-light-orange, $aa-black);
}

.mg {
  @include cc-task-item-variant($aa-light-orange, $aa-black);
}

.tirechk {
  @include cc-task-item-variant($aa-light-orange, $aa-black);
}

.transchk {
  @include cc-task-item-variant($aa-light-orange, $aa-black);
}

.rightstart {
  @include cc-task-item-variant($aa-white, $aa-red);
}

//MEL mappings
.melr {
  @include cc-task-item-variant($aa-black, $aa-white);
}

.melci {
  @include cc-task-item-variant($aa-black, $aa-white);
}

.melf {
  @include cc-task-item-variant($aa-yellow, $aa-dark-red);
}

.melfr {
  @include cc-task-item-variant($aa-yellow, $aa-dark-red);
}

.melfci {
  @include cc-task-item-variant($aa-yellow, $aa-dark-red);
}

.meld {
  @include cc-task-item-variant($aa-normal-orange, $aa-white);
}

.meldr {
  @include cc-task-item-variant($aa-normal-orange, $aa-white);
}

.meldci {
  @include cc-task-item-variant($aa-normal-orange, $aa-white);
}

.melq {
  @include cc-task-item-variant($aa-green, $aa-white);
}

.melqr {
  @include cc-task-item-variant($aa-green, $aa-white);
}

.melqci {
  @include cc-task-item-variant($aa-green, $aa-white);
}

.melqf {
  @include cc-task-item-variant($aa-yellow, $aa-dark-red);
}

.melqfri {
  @include cc-task-item-variant($aa-yellow, $aa-dark-red);
}

.melqfci {
  @include cc-task-item-variant($aa-yellow, $aa-dark-red);
}

.melqd {
  @include cc-task-item-variant($aa-normal-orange, $aa-white);
}

.melqdr {
  @include cc-task-item-variant($aa-normal-orange, $aa-white);
}

.melqcid {
  @include cc-task-item-variant($aa-normal-orange, $aa-white);
}

// ARMS/HERMES OVERRIDES

.arms,
.hermes {
  @include cc-task-item-variant($aa-light-orange, $aa-black);
}

/*#endregion******** TASK (T) *******/

/*#region******** CREW MANAGER (CM) *******/
.cm-modal {
  max-width: 90%;
}

.cm-station {
  margin-top: -$five;
}

.cm-crew-mem {
  max-height: 38rem;
  min-height: 38rem;
  overflow-y: auto;

  .p-datatable {
    .p-datatable-wrapper {
      overflow: revert-layer;
    }
  }
}

.cm-all-emp {
  margin-top: $thirty-four;
  text-align: right;
}

.cm-right-panel {
  width: 40rem;
  overflow: auto;
  max-height: 38rem;
  min-height: 38rem;
  z-index: 1010;
  position: absolute;
  float: right;
  background-color: $b4-blue;
  color: $aa-white;
  border-radius: $six;
  margin-top: $four;
  padding: $five $ten $ten $ten;

  .p-datatable .p-datatable-header {
    background: $b4-blue;
    border: none;
  }
}

.cm-all-crew {
  background-color: $aa-white;
  color: $aa-black;
  overflow: auto;
  max-height: 36.5rem;
  min-height: 36.5rem;
}

.cm-rp-name-search {
  width: 100%;
}

.cm-rp-name {
  width: $hundred-ninety-six;
}

.cm-rp-emp {
  width: $sixty;
}

.cm-rp-dot {
  width: $twenty-one;
}

.cm-table-header {
  color: $aa-light-gray;
  font-weight: normal;
}

.cm-table {
  border: $one solid $aa-light-gray;
  border-radius: $six;
}

.cm {
  .p-datatable {
    .p-datatable-thead > tr > th {
      font-weight: normal;
      color: $aa-medium-dark-gray;
    }

    .p-datatable-header {
      border-top-right-radius: $six;
      border-top-left-radius: $six;
    }
  }

  .cm-exception {
    color: $aa-white;
    background-color: $aa-medium-gray;
    border: $one solid $aa-medium-gray;
    border-radius: $three;
    padding: $two $three;
    font-family: 'AmericanSans Light';
    letter-spacing: 0.8px;
  }

  .cm-shift-status {
    min-width: 9rem;
    font-weight: bold;
  }

  .cm-online-status {
    max-width: $eight;
  }

  .cm-offline {
    padding-inline: $five;
  }

  .cm-online-font-size {
    font-size: $twenty-six;
  }
}

/*#endregion******** CREW MANAGER (CM) *******/

/*#region************ TOASTER TOAST**********/
.depature {
  background-color: $light-red;
  opacity: 0.7 !important;
}

/*#endregion************ TOASTER TOAST**********/
/* #region History */
.printLogo {
  font-size: 1.5rem;
  color: #007bff;
}

.w-7 {
  width: 7rem;
}

.w-10 {
  width: 10rem;
}

.w-14 {
  width: 14rem;
}

.headerBar {
  background-color: #ccc;
  color: black;
}

.taskToCol {
  padding: 0px !important;
  border: 0px !important;
}

/*#endregion History */
/*#region******** ALTERNATE TASKS *******/
.alternate-tasks .scrollArea table {
  thead tr {
    grid-template-columns: 0fr 1fr 1fr 1fr;
  }

  tbody tr {
    grid-template-columns: 0fr 1fr 1fr 1fr;
  }
}

.alternate-tasks-table {
  @extend .cc-table;
}

.alternate-tasks-item {
  @extend .cc-item;
}

.header-container {
  border-bottom: $aa-blue 0.07rem solid;
}

.header-checkbox-label {
  font-family: 'AmericanSansLight', Helvetica, sans-serif;
  font-size: $sixteen;
  color: $aa-blue;
}

.eaml-enabled {
  content: url('./assets/images/eaml.svg');
  display: inline-block;
  width: 2rem;
  vertical-align: middle;
  padding-left: $eight;
  padding-bottom: $four;
}

/*#endregion***** ALTERNATE TASKS *******/

/*#region******** MY TASKS *******/
.my-tasks-aircraft-turns {
  thead tr th {
    text-align: center;
    color: $aa-light-blue;
  }

  .my-tasks-hover {
    &:hover {
      background-color: #efefef;
      border-left-color: #ccc;
    }
  }

  tbody {
    &:hover {
      background-color: #efefef;
      border-left-color: #ccc;
    }

    &.active {
      background-color: $light-blue-hover;
      border-left-color: #48c;
      cursor: default;
    }

    &.delay-flight-with-task {
      background-color: $light-pink;
    }
  }

  td {
    border-top: none;
  }
}

.my-task-status-button {
  height: 1.8rem;
}

.my-task-status-tooltip {
  opacity: 70%;
}

/*#endregion***** MY TASKS *******/
/*#region***** BOW *******/
.bow-item-wc-number {
  border: 2px solid $aa-dark-blue-2;
  border-radius: $three;
  color: $aa-dark-blue-2;
  padding: $two $six;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.8rem;
  max-width: fit-content;
}

.info-icon {
  font-size: $eighteen;
  line-height: $eighteen;
}

lm-apps-lmo-ui-bow-assigned-members-list {
  div {
    max-width: fit-content;
  }
}

lm-apps-lmo-ui-cc-view-bow-items {
  table {
    tbody {
      tr {
        td {
          width: 20%;
        }
      }
    }
  }
}

lm-apps-lmo-ui-bow-task-status-icon {
  .fa {
    font-size: $sixteen;
  }

  .fa,
  .pi {
    vertical-align: middle;
  }
}

.delivered-icon {
  width: $fourteen;
  height: $twenty-two;
  background-image: url('./assets/images/delivered-icon.svg');
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  float: left;
}

/*#endregion***** BOW *******/

/*#region******** MEDIA QUERY *******/

@media screen {
  .vpanel {
    min-width: 1050px;
    position: relative;
    min-height: $one;
    padding: 0;
  }

  #cc-right-panel .vpanel {
    min-width: 700px;
  }

  .vpanel-container {
    margin-bottom: $ten;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .modal-xl {
    max-width: 1000px;
  }

  .navbar-AA {
    height: $fifty;
    padding-block: $seven;
  }
}

@media (max-width: 992px) {
  .cc-of-x {
    overflow-x: auto;
  }

  .navbar-header {
    float: none;
  }

  .navbar-toggle {
    display: block;
  }

  .navbar-collapse {
    border-top: $one solid transparent;
    box-shadow: inset 0 $one 0 rgba(255, 255, 255, 0.1);
  }

  .navbar-collapse.collapse {
    display: none !important;
  }

  .navbar-collapse.collapse.in {
    display: block !important;
  }

  .navbar-nav {
    float: none !important;
    margin: 0.75rem -1.5rem;
  }

  .navbar-nav > li {
    float: none;
  }

  .navbar-nav > li > a {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .modal-footer {
    float: right;
  }
}

.p-button:disabled {
  cursor: default;
  background-color: gray;
  border-color: gray;
}

@media (max-width: 1200px) {
  .dropdown-backdrop {
    position: unset;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 990;
  }
}

@media (min-width: 1200px) {
  .cc-resizer-dragger {
    top: 41%;
    position: relative;
    height: $fourty-one;
    width: $fourty-one;
    right: $fourty-one;
    color: #fff;
    border: $five solid #666666;
    font-size: $thirty;
    border-top-left-radius: $three;
    border-bottom-left-radius: $three;
    background: #666666;
    box-shadow: -0.6rem $four $seven $one #888;
    opacity: 0.5;
    -webkit-transition: 0.1s ease-out all;

    &:hover {
      opacity: 1;
      font-size: $thirty-two;
      right: $fourty-three;
      width: $fourty-three;
    }
  }

  .modal-xl {
    max-width: 1200px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 1200px;
  }
}

@media print {
  body * {
    visibility: hidden;
    -webkit-print-color-adjust: exact;

    print-color-adjust: exact;
  }

  .modal-xl,
  .modal-dialog {
    max-width: 100%;
    max-height: 100%;
  }

  .hidden-print {
    display: none;
  }

  a:not(.btn) {
    text-decoration: none;
  }

  a {
    color: unset;
  }

  .col-sm-3,
  .col-sm-9 {
    flex: auto;
    max-width: 100%;
  }

  lm-apps-alternate-tasks-task-detail,
  lm-apps-new-alternate-task,
  lm-apps-history-home,
  lm-apps-new-task {
    .printable,
    .printable * {
      visibility: visible;
    }

    .printable {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  lm-apps-lmo-ui-cc-view-aircraft-attribute,
  lm-apps-lmo-ui-task-detail {
    .printable-task,
    .printable-task * {
      visibility: visible;
    }

    .printable-task {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      font-size: 1.25rem;
    }

    .header-table {
      margin-top: 0;
    }

    .attribute-table {
      margin-top: 5rem;
      padding-left: 2rem;
    }

    .task-table {
      margin-top: 13rem;
      margin-left: -15px;
    }

    .col-sm-12 {
      flex: auto;
      max-width: 100%;
    }

    th {
      color: #212529;
    }
  }
}

/*#endregion******** MEDIA QUERY *******/
