// Do not want to keep the comments CSS in common-theme. If any css needed then move it only that comments css from here to common-theme.

// .aa-page-header {
//     padding-bottom: 0.9rem;
//     border-bottom: 0.1rem solid #eee;
//     margin: 2rem 0 2rem;
//   }

// /***********************
//   LOADING BAR
// */

// #loading-bar .bar {
//   top: 5rem;
// }

// #loading-bar-spinner {
//   top: 6rem;
// }

/***********************
  NAVBAR
*/

// .navbar-text {
//   margin-right: 0;
//   //font-weight: bold;
// }

// .navbar-form .aa.btn-link.disabled {
//   color: #333;
//   font-weight: bold;
// }

// .navbar-form select,
// .navbar-form .checkbox-inline {
//   color: #428bca;
// }

/***********************
  APPLICATION NAVBAR
*/

// .navbar-app {
//   background-color: #d0dae0;
// }

// .navbar-app .navbar-right > li a {
//   color: #00467f;
// }

// .navbar-brand .aa-logo {
//   height: 3rem;
//   margin-top: -0.5rem;
// }

/***********************
  MODULE NAVBAR
*/

// .navbar-module {
//   background: #fff;
//   top: 5rem;
// }

/***********************
  TYPOGRAPHY
*/

// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
// .h1,
// .h2,
// .h3,
// .h4,
// .h5,
// .h6 {
//   font-weight: normal;
//   font-family: 'AmericanSans';
// }

/***********************
  PANELS
*/

// .aa.panel {
//   border-width: 0;
//   box-shadow: 0 0 0;
// }

// .aa .panel-heading {
//   border-width: 0;
//   border-radius: 0;
//   margin-bottom: 2rem;
//   padding: 0.5rem 1rem;
//   position: relative;
// }

// .aa.panel .panel-heading:after,
// .aa.panel-default .panel-heading:after {
//   content: '';
//   border-left: 1.5rem solid transparent;
//   border-right: 1.5rem solid transparent;
//   border-top: 1.5rem solid #f5f5f5;
//   position: absolute;
//   bottom: -1.5rem;
//   right: 1.5rem;
//   z-index: 1000;
// }

// .aa.panel-info .panel-heading:after {
//   border-top-color: #d9edf7;
// }

// .aa.simple.panel .panel-heading {
//   background: transparent;
//   margin: 0;
//   padding: 0;
//   padding-top: 0.5rem;
// }

// .aa.simple.panel {
//   margin-bottom: 0;
// }

// .aa.simple.panel .panel-heading:after,
// .aa.simple.panel-default .panel-heading:after,
// .aa.simple.panel-info .panel-heading:after {
//   content: '';
//   border: none;
// }

/***********************
  WORK ITEMS
*/

// .aa-work-item-row {
//   background: transparent;
//   border-top: 0.1rem dashed #aaa;
//   position: relative;
//   margin-top: 0.5rem;
//   padding-left: 2.1rem;
//   padding-top: 0.5rem;
//   text-align: left !important;
//   overflow: hidden;
//   white-space: nowrap;
// }

// .aa-work-item-row > .table {
//   background: transparent;
//   margin-bottom: 0;
// }

// .aa-work-item-row-toggle {
//   padding-left: 0;
//   position: absolute;
//   left: 0;
//   top: 0.4rem;
// }

// .aa-work-item-row-toggle:hover {
//   text-decoration: none;
// }

// .aa-work-item-row-toggle:focus {
//   outline: none;
// }

// .aa-work-item {
//   background: #000;
//   border: 0.1rem solid #000;
//   border-radius: 0.4rem;
//   color: #fff;
//   display: inline-block;
//   margin-right: 0.4rem;
//   padding: 0.4rem;
// }

// .aa-work-item .h1,
// .aa-work-item .h2,
// .aa-work-item .h3,
// .aa-work-item .h4,
// .aa-work-item .h5,
// .aa-work-item .h6 {
//   display: inline;
// }

// .aa-work-item.daily,
// .aa-work-item.quick-fix-daily {
//   background-color: #f0ad4e;
//   border-color: #f0ad4e;
//   color: #000;
// }

// .aa-work-item.flight,
// .aa-work-item.quick-fix-flight {
//   background-color: #d1d532;
//   border-color: #d1d532;
//   color: #c30019;
// }

// .aa-work-item.acars-message {
//   background-color: #f0ad4e;
//   border-color: #f0ad4e;
//   color: #000;
// }

// .aa-work-item-row .table {
//   display: none;
// }

// .aa-work-item-row .table tr th,
// .aa-work-item-row .table tr td {
//   padding-left: 1.5rem;
//   padding-right: 1.5rem;
// }

// .aa-work-item-row .table tr th {
//   text-transform: uppercase;
// }

// .aa-work-item-row.expanded > .table {
//   display: block;
//   width: 100%;
// }

/***********************
  ASIDES
*/

// .aside,
// .aside-dialog,
// .aside-content,
// .aside-body {
//   height: 100%;
//   overflow-x: hidden;
// }

// .aside,
// .aside-dialog,
// .aside-content {
//   overflow-y: hidden;
// }

// .aside-content {
//   padding-top: 5.2rem;
//   padding-bottom: 4.7rem;
// }

// .aside-body {
//   position: relative !important;
//   overflow-y: auto !important;
// }

// .aside-header {
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
// }

// .aside-footer {
//   background: #eee;
//   border-top-color: #ccc;
//   padding: 0.6rem 1.5rem !important;
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   right: 0;
// }

// /***********************
//   STATION SELECT
// */

// .aa-station-select {
//   max-width: 24rem;
// }

// /***********************
//   LIST GROUPS
// */

// .list-group-section {
//   color: #999;
//   font-size: 1.4rem;
//   font-weight: bold;
//   text-transform: uppercase;
//   margin: 0 0 1rem 0;
// }

// .aa.list-group,
// .aa.list-group-item {
//   border-color: transparent;
// }

// .aa.list-group-item:first-child,
// .aa.list-group-item:last-child {
//   border-radius: 0;
// }

// a.aa.list-group-item > .list-group-item-heading {
//   color: #428bca;
// }

// a.aa.list-group-item.active > .list-group-item-heading {
//   color: #fff;
// }

// a.aa.list-group-item > .list-group-item-text {
//   padding-top: 0.4rem;
// }

/***********************
  AMT TABLE
*/

// .amt-table .table-head {
//   border-bottom: 0.2rem solid #ddd;
//   color: #5bc0de;
//   font-weight: bold;
//   padding: 0.5rem;
//   text-transform: uppercase;
// }

// .amt-table .table-row {
//   border-bottom: 0.1rem solid #ddd;
//   padding: 0.5rem;
// }

// .amt-table .table-row:nth-child(odd) {
//   background-color: #f9f9f9;
// }

// .amt-table .table-row .table-cell {
//   text-align: right;
// }

// .amt-table .table-row .table-cell > .h3 {
//   display: block;
//   margin: 0;
// }

//#endregion App.css

//#region AA.css

/*Padding*/

// .aa-pad-sm {
//   padding: 0.1rem 0.5rem 0.5rem 0.5rem;
// }

// .aa-pad-left-sm {
//   padding-left: 0.5rem;
// }

// .aa-pad-left {
//   padding-left: 1rem !important;
// }

// .aa-pad-right-sm {
//   padding-right: 0.5rem;
// }

// .aa-pad-right-lg {
//   padding-right: 1rem;
// }

// .aa-pad-top-sm {
//   padding-top: 0.5rem;
// }

// .aa-pad-top-lg {
//   padding-top: 1rem;
// }

// .aa-pad-bottom-sm {
//   padding-bottom: 0.5rem;
// }

// .aa-pad-bottom-lg {
//   padding-bottom: 1rem;
// }

// .no-padding {
//   padding: 0;
// }

// .no-margin {
//   margin: 0;
// }

// .aa-margin-1 {
//   margin: 1rem;
// }

// .aa-margin-heading-btn {
//   margin-top: -0.6rem;
// }

// .aa-margin-bottom-none {
//   margin-bottom: 0;
// }

// .aa-margin-bottom-sm {
//   margin-bottom: 0.5rem;
// }

// .aa-margin-bottom-lg {
//   margin-bottom: 1rem;
// }

// .aa-margin-top-sm {
//   margin-top: 0.5rem;
// }

// .aa-margin-top-lg {
//   margin-top: 1rem;
// }

// /*Borders*/
// .aa-border-blue {
//   border: 0.1rem solid #0078d2;
// }

// .us-border-yellow {
//   border: 0.1rem solid #d1d532;
// }

/*Headings Overrides*/

// /*Width Overrides*/
// .aa-fullwidth {
//   min-width: 100%;
// }

// .aa-ellipsis {
//   overflow: hidden;
//   text-overflow: ellipsis;
//   white-space: nowrap;
//   color: red;
// }

// .person-handle {
//   cursor: -webkit-grab !important;
//   cursor: -moz-grab !important;
//   width: 2.2rem;
//   float: left;
//   padding: 0 !important;
// }

// .person-handle > span {
//   color: #707070;
// }

// .person-handle > span.first {
//   margin-left: 0.1rem;
//   padding: 0.2rem;
// }

// .person-handle > span.second {
//   margin-left: -0.6rem;
//   padding: 0.2rem;
// }

// .selection-panel {
//   border: 0.1rem solid #e3e3e3;
//   -webkit-box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.05);
//   box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.05);
//   padding: 0.9rem;
//   border-radius: 0.3rem;
//   height: 58rem;
//   width: 70rem;
//   float: right;
//   -moz-transition: all ease 0.35s;
//   -o-transition: all ease 0.35s;
//   transition: all ease 0.35s;
//   right: 0;
//   top: 1rem;
//   position: absolute;
//   background: #00467f;
//   color: #fff;
// }

// .selection-container {
//   height: 50rem;
//   overflow-x: hidden;
//   overflow-y: auto;
//   border: 0.1rem solid #ddd;
//   background: #fff;
//   color: #000;
// }

// .hide-selection-panel {
//   width: 0;
//   padding: 0;
// }

// .hide-selection-panel .selection-panel-content,
// .hide-selection-panel-remove .selection-panel-content {
//   opacity: 0;
// }

// .hide-selection-panel > .add-employee-icon {
//   opacity: 0.5;
//   background: #00467f;
//   color: white;
// }

// .crew-panel-nav {
//   background-color: #f8f8f8;
//   border-bottom: 0.1rem solid #0078d2;
//   color: #777;
// }

// .crew-panel {
//   margin-right: 1.5rem;
//   margin-left: 1.5rem;
//   border: 0.1rem solid #e3e3e3;
//   border-radius: 0.3rem;
//   height: 50rem;
// }

// .crew-container {
//   height: 43.8rem;
//   overflow-y: auto;
//   overflow-x: hidden;
// }

// .crew-container > div {
//   padding-top: 0.3rem;
//   padding-bottom: 0.3rem;
//   border-top: 0.1rem solid #ddd;
//   border-left: 0.3rem solid transparent;
// }

// .crew-container > div:hover {
//   background-color: #f5f5f5;
//   border-left-color: #ccc;
// }

// .crew-panel-header > div {
//   font-weight: bold;
// }

// .expand-icon {
//   transform: rotate(90deg);
//   transform-origin: left top 0;
//   position: absolute;
//   display: inline-flex;
//   margin: 1.2rem;
//   width: 10rem;
// }

// .options-container {
//   padding-right: 0;
//   padding-left: 0;
//   margin-right: auto;
//   margin-left: auto;
// }

// .search-box {
//   width: 85%;
//   position: relative;
//   min-height: 0.1rem;
//   float: left;
// }

// .no-padding {
//   padding: 0;
// }

// .app + div {
//   margin-top: 3.8rem;
// }

// .ui-sortable-helper {
//   border: 0.1rem solid grey;
//   background: white;
//   box-shadow: 0.8rem 0.9rem 2rem -0.4rem;
//   height: 2.5rem !important;
//   min-width: 37rem !important;
// }

// .add-employee-icon {
//   background: #00467f;
//   color: white;
//   text-align: center;
//   width: 4rem;
//   height: 4rem;
//   line-height: 4rem;
//   position: absolute;
//   border-top: 0.1rem solid #e3e3e3;
//   border-left: 0.1rem solid #e3e3e3;
//   border-bottom: 0.1rem solid #e3e3e3;
//   border-radius: 0.5rem 0 0 0.5rem;
//   cursor: pointer;
//   font-size: 2.7rem;
//   transition: all 0.1s linear 0s;
//   left: -4rem;
//   top: 1.5rem;
//   z-index: 1000;
// }

// .add-employee-icon:hover {
//   opacity: 1;
// }

// .selection-container::-webkit-scrollbar,
// .crew-container::-webkit-scrollbar {
//   width: 0.6rem;
// }

// .selection-container::-webkit-scrollbar-track,
// .crew-container::-webkit-scrollbar-track {
//   display: none;
// }

// .selection-container::-webkit-scrollbar-thumb,
// .crew-container::-webkit-scrollbar-thumb {
//   border-radius: 0.3rem;
//   background-color: rgba(0, 0, 0, 0.15);
// }

//#endregion AA.css

//#region Header.less

// .navbar-default {
//   background-image: none;
//   margin-bottom: 0.3rem;

//   .navbar-text {
//     color: $aa-white;
//     margin-left: 1.5rem;
//     margin-right: 1.5rem;
//   }
// }

// .aa-heading {
//   background: #e1f0fa !important;
// }

// .border-top-color-aa {
//   background: #e1f0fa !important;
// }

// .navmenu-AA {
//   background-color: $aa-blue;
// }

//#endregion Header.less

//#region App.less

// //#region Loading-bar.less
// #loading-bar,
// #loading-bar-spinner {
//   pointer-events: none;
//   -webkit-pointer-events: none;
//   -webkit-transition: 350ms linear all;
//   -moz-transition: 350ms linear all;
//   -o-transition: 350ms linear all;
//   transition: 350ms linear all;
// }

// #loading-bar.ng-enter,
// #loading-bar.ng-leave.ng-leave-active,
// #loading-bar-spinner.ng-enter,
// #loading-bar-spinner.ng-leave.ng-leave-active {
//   opacity: 0;
// }

// #loading-bar.ng-enter.ng-enter-active,
// #loading-bar.ng-leave,
// #loading-bar-spinner.ng-enter.ng-enter-active,
// #loading-bar-spinner.ng-leave {
//   opacity: 1;
// }

// #loading-bar .bar {
//   -webkit-transition: width 450ms;
//   -moz-transition: width 450ms;
//   -o-transition: width 450ms;
//   transition: width 450ms;
//   background: red;
//   position: fixed;
//   z-index: 10002;
//   top: 5rem;
//   left: 0;
//   width: 100%;
//   height: 0.4rem;
//   border-bottom-right-radius: 0.1rem;
//   border-top-right-radius: 0.1rem;
// }

// #loading-bar .peg {
//   position: absolute;
//   width: 7rem;
//   right: 0;
//   top: 0;
//   height: 0.2rem;
//   opacity: 0.45;
//   -moz-box-shadow: red 0.1rem 0 0.6rem 0.1rem;
//   -ms-box-shadow: red 0.1rem 0 0.6rem 0.1rem;
//   -webkit-box-shadow: red 0.1rem 0 0.6rem 0.1rem;
//   box-shadow: red 0.1rem 0 0.6rem 0.1rem;
//   -moz-border-radius: 100%;
//   -webkit-border-radius: 100%;
//   border-radius: 100%;
// }

// #loading-bar-spinner {
//   background-position: 50% 2rem;
//   background-repeat: no-repeat;
//   text-align: center;
//   font-size: 2.6rem;
//   padding: 1rem;
//   position: absolute;
//   top: -0.6rem;
//   width: 100%;
//   z-index: 99999;
// }

// #loading-bar-spinner .spinner-text {
//   margin: 0 auto;
//   width: 35rem;
//   background-color: #fff;
//   color: #0078d2;
//   font-weight: bolder;
//   border: 0.2rem solid #d0dae0;
//   -moz-border-radius: 2rem;
//   -webkit-border-radius: 2rem;
//   -khtml-border-radius: 2rem;
//   border-radius: 2rem;
// }

// @-webkit-keyframes loading-bar-spinner {
//   0% {
//     -webkit-transform: rotate(0deg);
//     transform: rotate(0deg);
//   }

//   100% {
//     -webkit-transform: rotate(360deg);
//     transform: rotate(360deg);
//   }
// }

// @-moz-keyframes loading-bar-spinner {
//   0% {
//     -moz-transform: rotate(0deg);
//     transform: rotate(0deg);
//   }

//   100% {
//     -moz-transform: rotate(360deg);
//     transform: rotate(360deg);
//   }
// }

// @-o-keyframes loading-bar-spinner {
//   0% {
//     -o-transform: rotate(0deg);
//     transform: rotate(0deg);
//   }

//   100% {
//     -o-transform: rotate(360deg);
//     transform: rotate(360deg);
//   }
// }

// @-ms-keyframes loading-bar-spinner {
//   0% {
//     -ms-transform: rotate(0deg);
//     transform: rotate(0deg);
//   }

//   100% {
//     -ms-transform: rotate(360deg);
//     transform: rotate(360deg);
//   }
// }

// @keyframes loading-bar-spinner {
//   0% {
//     transform: rotate(0deg);
//   }

//   100% {
//     transform: rotate(360deg);
//   }
// }

// //#endregion Loading-bar.less

// .refresh-icon-color-landscape {
//   color: white;
// }

// .refresh-landscape {
//   background-color: transparent;
//   display: none;
//   border: none;
//   padding-top: 1.5rem;
// }

// .select-all-stations {
//   width: 96%;
// }

//#region scrollable.less

// .nano {
//   position: relative;
//   width: 100%;
//   height: 100%;
//   overflow: hidden;
// }

// .nano > .nano-content {
//   position: absolute;
//   overflow: scroll;
//   overflow-x: hidden;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
// }

// .nano > .nano-content:focus {
//   outline: thin dotted;
// }

// .nano > .nano-content::-webkit-scrollbar {
//   display: none;
// }

// .nano > .nano-pane {
//   background: #131313;
//   background: rgba(0, 0, 0, 0.25);
//   position: absolute;
//   width: 1rem;
//   right: 0;
//   top: 0;
//   bottom: 0;
//   visibility: hidden \9;
//   opacity: 0.01;
//   filter: alpha(opacity=25);
//   -webkit-transition: 0.2s;
//   -moz-transition: 0.2s;
//   -o-transition: 0.2s;
//   transition: 0.2s;
//   -moz-border-radius: 0.5rem;
//   -webkit-border-radius: 0.5rem;
//   border-radius: 0.5rem;
// }

// .nano > .nano-pane > .nano-slider {
//   background: #131313;
//   background: rgba(0, 0, 0, 0.5);
//   position: relative;
//   margin: 0 0.1rem;
//   -moz-border-radius: 0.3rem;
//   -webkit-border-radius: 0.3rem;
//   border-radius: 0.3rem;
//   filter: alpha(opacity=50);
// }

// .nano:hover > .nano-pane,
// .nano-pane.active,
// .nano-pane.flashed {
//   visibility: visible \9;
//   opacity: 0.99;
// }

// .has-scrollbar > .nano-content::-webkit-scrollbar {
//   display: block;
// }

//#endregion scrollable.less

// .aa-heading {
//   background: #e1f0fa !important;
// }

// .border-top-color-aa {
//   background: #e1f0fa !important;
// }

// .navmenu-AA {
//   background-color: #0078d2;
// }

// .brand img,
// .brand {
//   margin-left: -3.5rem;
//   max-height: 5rem;
// }

// .brand-aa-env-label-text {
//   font-size: 1.4rem;
//   margin-left: -0.25rem;
// }

// //#region offcanvas.less
// .offcanvas > *[data-scrollable] {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   padding-top: 5rem;
// }

// //#endregion offcanvas.less

// //#region panels.less
// .panel {
//   border-width: 0;
//   box-shadow: 0 0 0;
//   position: relative;
// }

// .aa-heading {
//   border-width: 0;
//   border-radius: 0;
//   margin-bottom: 2rem;
//   padding: 0.5rem 1rem;
//   position: relative;
// }

// .aa-heading:after {
//   content: '';
//   border-left: 1.5rem solid transparent;
//   border-right: 1.5rem solid transparent;
//   border-top: 1.5rem solid transparent;
//   position: absolute;
//   bottom: -1.5rem;
//   right: 1.5rem;
//   z-index: 1000;
//   border-top-color: #e1f0fa !important;
// }

// .panel-default > .panel-heading {
//   color: #333;
//   background-color: #ccc;
// }

// .panel-default > .panel-heading .badge {
//   color: #ccc;
//   background-color: #333;
// }

// .panel-default > .panel-heading:after {
//   border-top-color: #ccc;
// }

// .panel-primary > .panel-heading {
//   color: #333;
//   background-color: #ccc;
// }

// .panel-primary > .panel-heading .badge {
//   color: #ccc;
//   background-color: #333;
// }

// .panel-primary > .panel-heading:after {
//   border-top-color: #ccc;
// }

// .panel-primary > .panel-heading {
//   border-color: transparent;
// }

// .panel-success > .panel-heading {
//   color: #333;
//   background-color: #ccc;
// }

// .panel-success > .panel-heading .badge {
//   color: #ccc;
//   background-color: #333;
// }

// .panel-success > .panel-heading:after {
//   border-top-color: #ccc;
// }

// .panel-info {
//   border-radius: 0;
// }

// .panel-info > .panel-heading {
//   color: #333;
//   background-color: #ccc;
// }

// .panel-info > .panel-heading .badge {
//   color: #ccc;
//   background-color: #333;
// }

// .panel-info > .panel-heading:after {
//   border-top-color: #ccc;
// }

// .panel-info .navbar-default,
// .panel-info .navmenu-default .navbar-default {
//   border-top-width: 0;
//   border-left-width: 0;
//   border-right-width: 0;
//   border-bottom: 0.1rem solid #0078d2;
//   border-radius: 0;
// }

// .panel-info .navbar-default .table,
// .panel-info .navmenu-default .navbar-default .table {
//   table-layout: fixed;
// }

// .panel-info .navbar-default .offcanvas,
// .panel-info .navmenu-default .navbar-default .offcanvas {
//   background-color: #0078d2;
//   color: #fff;
// }

// .panel-info .navbar-default .navbar-brand,
// .panel-info .navmenu-default .navbar-default .navbar-brand {
//   font-size: 2rem;
// }

// .panel-warning > .panel-heading {
//   color: #333;
//   background-color: #ccc;
// }

// .panel-warning > .panel-heading .badge {
//   color: #ccc;
//   background-color: #333;
// }

// .panel-warning > .panel-heading:after {
//   border-top-color: #ccc;
// }

// .panel-danger > .panel-heading {
//   color: #333;
//   background-color: #ccc;
// }

// .panel-danger > .panel-heading .badge {
//   color: #ccc;
//   background-color: #333;
// }

// .panel-danger > .panel-heading:after {
//   border-top-color: #ccc;
// }

//#endregion panels.less

// th {
//   font-weight: 300;
//   //color: #9da6ab;
// }

// .navbar-brand {
//   position: relative;
// }

// .navbar-brand img {
//   height: 3rem;
//   position: absolute;
//   top: 1rem;
// }

// .autoScroll_y {
//   overflow-y: auto;
//   padding-left: 2rem;
// }

// .noPadRight {
//   padding-right: 0 !important;
// }

// .noPadLeft {
//   padding-left: 0 !important;
// }

// .noPadding {
//   padding: 0 !important;
// }

// .bannerCallout {
//   line-height: 2.5;
//   padding-left: 2rem;
//   color: #fff;
// }

// .amt-attributes {
//   cursor: pointer;
//   border-left: 0.5rem solid transparent;
//   vertical-align: middle !important;
// }

// .amt-attributes .aa,
// .amt-attributes .AA {
//   background-image: url('/content/images/aa-icon.png');
//   background-repeat: no-repeat;
//   background-position-x: right;
//   background-position-y: 0.3rem;
//   padding-right: 2.5rem;
// }

// .amt-attributes .us,
// .amt-attributes .US {
//   background-image: url('/content/images/us-icon.png');
//   background-repeat: no-repeat;
//   background-position-x: right;
//   background-position-y: 0.3rem;
//   padding-right: 2.5rem;
// }

// .attr span.h3 {
//   font-size: 1.8rem !important;
// }

// span.attributes-icon {
//   height: 2.5rem;
// }

// .navmenu-fixed-right {
//   overflow-y: hidden !important;
//   padding-bottom: 5rem;
// }

// .table.table-flifo > tr > th {
//   vertical-align: text-bottom;
//   padding-bottom: 0;
//   overflow: hidden;
//   text-overflow: ellipsis;
// }

// .table.table-flifo > tbody > tr > td.cc-maint-tray,
// .table.table-flifo > tbody > tr > td.cc-unassigned-tray {
//   overflow: visible;
//   padding: 0.3rem;
// }

// .dropdown-task,
// .open > .dropdown-menu {
//   overflow-x: hidden !important;
//   overflow-y: auto !important;
//   max-height: 400% !important;
//   width: 100%;
// }

// .open > .dropdown-menu.clearfix {
//   max-height: inherit !important;
//   width: inherit !important;
// }

// .dropdown-menu > li {
//   padding-left: 0.8rem;
//   padding-right: 0.8rem;
//   cursor: pointer;
// }

// .border-radius-three {
//   border-radius: 0.3rem;
// }

// .person-handle {
//   cursor: -webkit-grab !important;
//   cursor: -moz-grab !important;
//   cursor: move;
//   width: 2.2rem;
//   float: left;
//   padding: 0 !important;
// }

// .person-handle > span {
//   color: #707070;
// }

// .person-handle > span.first {
//   margin-left: 0.1rem;
//   padding: 0.2rem;
// }

// .person-handle > span.second {
//   margin-left: -0.6rem;
//   padding: 0.2rem;
// }

// .selected {
//   background-color: #e6e6e6 !important;
// }

// .selectedCC {
//   background-color: #e6e6e6;
// }

// .selection-panel {
//   border: 0.1rem solid #e3e3e3;
//   -webkit-box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.05);
//   box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.05);
//   padding: 0.9rem;
//   border-radius: 0.3rem;
//   height: 58rem;
//   width: 70rem;
//   float: right;
//   -moz-transition: all ease 0.35s;
//   -o-transition: all ease 0.35s;
//   transition: all ease 0.35s;
//   right: 0;
//   top: 1rem;
//   position: absolute;
//   background: #0078d2;
//   background: -webkit-linear-gradient(#0078d2, #0065b3);
//   background: -o-linear-gradient(#0078d2, #0065b3);
//   background: -moz-linear-gradient(#0078d2, #0065b3);
//   background: linear-gradient(#0078d2, #0065b3);
//   color: #fff;
// }

// .selection-container {
//   height: 50rem;
//   border: 0.1rem solid #ddd;
//   font-size: 1.4rem;
//   background: #fff;
//   color: #333;
// }

// .hide-selection-panel {
//   width: 0;
//   padding: 0;
// }

// .hide-selection-panel .selection-panel-content,
// .hide-selection-panel-remove .selection-panel-content {
//   opacity: 0;
// }

// .hide-selection-panel > .add-employee-icon {
//   opacity: 0.5;
// }

// .crew-panel-nav {
//   background-color: #f8f8f8;
//   border-bottom: 0.1rem solid #0078d2;
//   color: #777;
// }

// .crew-panel {
//   border: 0.1rem solid #e3e3e3;
//   border-radius: 0.3rem;
//   height: 50rem;
// }

// .crew-container {
//   height: 43.8rem;
//   overflow-y: auto;
//   overflow-x: hidden;
//   font-size: 1.4rem;
//   font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
// }

// .crew-container > div {
//   padding-top: 0.3rem;
//   padding-bottom: 0.3rem;
//   border-top: 0.1rem solid #ddd;
//   border-left: 0.3rem solid transparent;
// }

// .crew-container > div:hover {
//   background-color: #f5f5f5;
//   border-left-color: #ccc;
// }

// .crew-panel-header > div {
//   font-weight: bold;
// }

// .expand-icon {
//   transform: rotate(90deg);
//   transform-origin: left top 0;
//   position: absolute;
//   display: inline-flex;
//   margin: 1.2rem;
//   width: 10rem;
// }

// .options-container {
//   padding-right: 0;
//   padding-left: 0;
//   margin-right: auto;
//   margin-left: auto;
// }

// .search-box {
//   width: 85%;
//   position: relative;
//   min-height: 0.1rem;
//   float: left;
// }

// .no-padding {
//   padding: 0;
// }

// .padding-right {
//   padding-right: 0.5rem;
// }

// .padding-left {
//   padding-left: 0.5rem;
// }

// .app + div {
//   margin-top: 3.8rem;
// }

// .ui-sortable-helper {
//   border: 0.1rem solid grey;
//   background: #fff;
//   box-shadow: 0.8rem 0.9rem 2rem -0.4rem;
//   height: 2.5rem !important;
//   min-width: 37rem !important;
// }

// .add-employee-icon {
//   background: #0078d2;
//   color: #fff;
//   text-align: center;
//   width: 4rem;
//   height: 4rem;
//   line-height: 4rem;
//   position: absolute;
//   border-top: 0.1rem solid #e3e3e3;
//   border-left: 0.1rem solid #e3e3e3;
//   border-bottom: 0.1rem solid #e3e3e3;
//   border-radius: 0.5rem 0 0 0.5rem;
//   cursor: pointer;
//   font-size: 2.7rem;
//   transition: all 0.1s linear 0s;
//   left: -4rem;
//   top: 1.5rem;
//   z-index: 1000;
// }

// .add-employee-icon:hover {
//   width: 4.8rem;
//   height: 4.5rem;
//   left: -4.8rem;
//   font-size: 3rem;
//   opacity: 1;
// }

// .selection-container::-webkit-scrollbar,
// .crew-container::-webkit-scrollbar {
//   width: 0.6rem;
// }

// .selection-container::-webkit-scrollbar-track,
// .crew-container::-webkit-scrollbar-track {
//   display: none;
// }

// .selection-container::-webkit-scrollbar-thumb,
// .crew-container::-webkit-scrollbar-thumb {
//   border-radius: 0.3rem;
//   background-color: rgba(0, 0, 0, 0.15);
// }

// .loser {
//   cursor: move;
// }

// .pointer {
//   cursor: pointer;
// }

// .loader {
//   border: 1rem solid #f3f3f3;
//   border-radius: 50%;
//   border-top: 1rem solid #3498db;
//   width: 6rem;
//   height: 6rem;
//   -webkit-animation: spin 2s linear infinite;
//   animation: spin 2s linear infinite;
// }

// .glyphicon-nofont {
//   position: relative;
//   top: 0.1rem;
//   display: inline-block;
//   font-style: normal;
//   font-weight: normal;
//   line-height: 1;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

// @-webkit-keyframes spin {
//   0% {
//     -webkit-transform: rotate(0deg);
//   }

//   100% {
//     -webkit-transform: rotate(360deg);
//   }
// }

// @keyframes spin {
//   0% {
//     transform: rotate(0deg);
//   }

//   100% {
//     transform: rotate(360deg);
//   }
// }

// .col-max {
//   max-width: 10.5rem;
// }

// .ccmaintitem {
//   padding: 0.2rem;
//   white-space: nowrap;
//   position: relative;
//   cursor: pointer;
//   border-radius: 0.3rem;
// }

// .cctraycontainer {
//   margin: 0.2rem;
//   overflow: visible !important;
//   text-overflow: ellipsis;
// }

// .maintenance-notification {
//   background-color: #000;
//   border: 0.2rem solid #000;
//   color: #fff;
//   padding: 0.3rem;
//   white-space: nowrap;
//   position: relative;
//   border-radius: 0.3rem;
// }

// .maintenance-notification-tray {
//   overflow: hidden;
// }

// .hexagon {
//   position: relative;
//   width: 2.5rem;
//   height: 14.4.3rem;
//   background-color: #f52305;
//   margin: 7.2.2rem 0;
//   text-align: left;
//   display: block;
//   font-size: 0.8em;
//   font-weight: bold;
//   line-height: 1.2;
//   padding: 0.2rem 0.3rem 0.2rem 0;
//   color: #fff;
// }

// .hexagon:before,
// .hexagon:after {
//   content: '';
//   position: absolute;
//   width: 0;
//   border-left: 1.25rem solid transparent;
//   border-right: 1.25rem solid transparent;
// }

// .hexagon:before {
//   bottom: 100%;
//   border-bottom: 0.722rem solid red;
// }

// .hexagon:after {
//   top: 100%;
//   width: 0;
//   border-top: 0.722rem solid red;
// }

// .deferral.qfx:before {
//   content: 'Q';
// }

// .deferral.qdaily:before {
//   content: 'QD';
// }

// .deferral.qcycle:before {
//   content: 'QF';
// }

// .deferral.daily:before {
//   content: 'D';
// }

// .deferral.cycle:before {
//   content: 'F';
// }

// .deferral.melr:before {
//   content: 'R';
// }

// .deferral.melci:before {
//   content: 'CI';
// }

// .deferral.melf:before {
//   content: 'F';
// }

// .deferral.melfr:before {
//   content: 'FR';
// }

// .deferral.melfci:before {
//   content: 'FCI';
// }

// .deferral.meld:before {
//   content: 'D';
// }

// .deferral.meldr:before {
//   content: 'DR';
// }

// .deferral.meldci:before {
//   content: 'DCI';
// }

// .deferral.melq:before {
//   content: 'Q';
// }

// .deferral.melqr:before {
//   content: 'QR';
// }

// .deferral.melqci:before {
//   content: 'QCI';
// }

// .deferral.melqf:before {
//   content: 'QF';
// }

// .deferral.melqfri:before {
//   content: 'QFRI';
// }

// .deferral.melqfci:before {
//   content: 'QFCI';
// }

// .deferral.melqd:before {
//   content: 'QD';
// }

// .deferral.melqdr:before {
//   content: 'QDR';
// }

// .deferral.melqcid:before {
//   content: 'QCID';
// }

// .maintenance-notification-tray {
//   background-color: #eee;
//   margin-top: 0.5rem;
//   padding: 0.8rem 0.5rem;

//   .btn {
//     padding: 0.3rem;
//   }

//   .maintenance-notification {
//     margin-right: 0.3rem;
//   }
// }

// .cc-maint-item {
//   color: #fff;
//   padding-left: 0.3rem;
//   padding-right: 0.3rem;
//   white-space: nowrap;
//   position: relative;
//   border-radius: 0.3rem;
// }

// .cc-italic {
//   font: italic bold 1.2rem/3rem Georgia, serif;
//   pointer-events: none;
// }

// .disabled {
//   pointer-events: none;
//   cursor: default;
// }

// .cc-maint-tray {
//   background-color: #eee;
//   margin-top: 0.5rem;
//   padding: 0.8rem 0.5rem;

//   .btn {
//     padding: 0.3rem;
//   }

//   .cc-maint-item {
//     float: left;
//   }

//   // .dropdown-toggle {
//   //   display: none !important;
//   // }

//   .cc-tray-container {
//     margin-right: 0;
//     overflow: hidden;
//     text-overflow: ellipsis;
//   }

//   // .dropdown-menu {
//   //   top: 0;
//   //   padding-top: 0.5rem;
//   //   padding-bottom: 0.5rem;
//   //   overflow-y: auto;
//   //   max-height: 40rem;
//   //   z-index: 2000;
//   //   position: static !important;
//   // }

//   .cc-maint-item {
//     margin: 0.2rem;
//     overflow: hidden;
//     text-overflow: ellipsis;
//   }
// }

// .cc-master-item {
//   cursor: pointer;
//   border-left: 0.5rem solid transparent;

//   .aa,
//   .AA {
//     background-image: url('/content/images/aa-icon.png');
//     background-repeat: no-repeat;
//     background-position-x: right;
//     background-position-y: 0.3rem;
//     padding-right: 2.5rem;
//   }

//   .us,
//   .US {
//     background-image: url('/content/images/us-icon.png');
//     background-repeat: no-repeat;
//     background-position-x: right;
//     background-position-y: 0.3rem;
//     padding-right: 2.5rem;
//   }

//   &:hover {
//     background-color: #efefef;
//     border-left-color: #ccc;
//   }

//   &.active {
//     border-left-color: #48c;
//     cursor: default;

//     td {
//       background-color: #eff6fd !important;
//     }
//   }

//   .h3 {
//     display: block;
//     margin: 0;
//   }

//   .etops {
//     background: transparent;
//     color: red;
//     padding: 0.3rem;
//   }
// }

// .cc-new-notification-icon {
//   background-color: #000;
//   color: #fff;
//   padding: 0.3rem;
//   white-space: nowrap;
//   position: relative;
//   border-radius: 0.3rem;
// }

// .cc-italic {
//   font: italic bold 1.2rem/3rem Georgia, serif;
//   pointer-events: none;
// }

// .cc-task-item {
//   color: #fff;
//   padding-left: 0.3rem;
//   padding-right: 0.3rem;
//   white-space: nowrap;
//   position: relative;
//   border-radius: 0.3rem;
//   float: left;
// }

// .cc-italic {
//   font: italic bold 1.2rem/3rem Georgia, serif;
//   pointer-events: none;
// }

// .disabled {
//   pointer-events: none;
//   cursor: default;
// }

// .cc-unassigned-tray {
//   background-color: transparent;
//   margin-top: 0.5rem;
//   padding: 0.8rem 0.5rem;

//   .btn {
//     padding: 0.3rem;
//   }

//   // .dropdown-toggle {
//   //   display: none;
//   // }

//   .cc-tray-container {
//     margin-right: 0;
//     overflow: hidden;
//     text-overflow: ellipsis;
//   }

//   // .dropdown-menu {
//   //   top: 0;
//   //   padding-top: 0.5rem;
//   //   padding-bottom: 0.5rem;
//   //   overflow-y: auto;
//   //   max-height: 40rem;
//   // }

//   .cc-task-item {
//     margin: 0.2rem;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     border-radius: 0.3rem;
//   }
// }

// .aa-caret-sort {
//   .caret {
//     border-top: transparent;
//   }

//   .ascending .caret {
//     border-top: transparent;
//     border-bottom: 0.4rem solid;
//     margin-bottom: 0.4rem;
//   }

//   .descending .caret {
//     border-top: 0.4rem solid;
//     border-bottom: transparent;
//     margin-bottom: 0;
//   }
// }

// .hidden {
//   visibility: hidden !important;
// }

// .air-interrupt {
//   color: #777;
// }

// .air-interrupt.I {
//   background: red;
//   color: #fff;
//   padding: 0.3rem;
// }

// .arrival-flight-indicator {
//   color: #777;
// }

// .arrival-flight-indicator.O,
// .arrival-flight-indicator.I {
//   color: #ffa500;
//   padding: 0.3rem;
// }

// .arrival-status {
//   color: #777;
// }

// .arrival-status.E,
// .arrival-status.C,
// .arrival-status.H,
// .arrival-status.A,
// .arrival-status.N {
//   color: #ffa500;
//   padding: 0.3rem;
// }

// .arrival-status.T {
//   color: green;
//   padding: 0.3rem;
// }

// .departure-flight-indicator {
//   color: #777;
// }

// .departure-flight-indicator.O,
// .departure-flight-indicator.F {
//   color: #ffa500;
//   padding: 0.3rem;
// }

// .departure-status {
//   color: #777;
// }

// .departure-status.E,
// .departure-status.N {
//   color: #ffa500;
//   padding: 0.3rem;
// }

// .departure-status.T {
//   color: green;
//   padding: 0.3rem;
// }

// .ground-interrupt {
//   color: #777;
// }

// .ground-interrupt.G {
//   background: red;
//   color: #fff;
//   padding: 0.3rem;
// }

// .ground-time {
//   color: #fff;
// }

// .ground-time.D {
//   color: #c30019;
// }

// .ground-time.W {
//   color: #faaf00;
// }

// .ground-time.S {
//   color: #00b989;
// }

// .offcanvas {
//   border: none;
//   box-shadow: inset rem rem 2rem 0.3rem #888;
// }

// .offcanvas .panel {
//   margin-bottom: 0;
//   border-radius: 0;
// }

// .offcanvas .navbar-default,
// .offcanvas .navmenu-default {
//   border-bottom: none !important;
//   color: #fff;
//   margin-bottom: 0;
//   background-color: #0078d2;
// }

// .offcanvas .navbar-default .btn-link,
// .offcanvas .navmenu-default .btn-link {
//   color: #fff;
// }

// .offcanvas .navbar-brand {
//   font-size: 2rem;
//   color: #fff;
// }

// .offcanvas .navbar-brand:hover {
//   color: #fff;
// }

// .offcanvas .setbackground {
//   background-color: #d0dae0;
// }

// .offcanvas .navbar-nav > li > a:focus {
//   color: #fff;
// }

// .offcanvas .panel-primary,
// .offcanvas .panel-default {
//   background-color: transparent !important;
//   color: #131313;
//   margin-bottom: 0;
// }

// .offcanvas .panel-primary .table:last-child,
// .offcanvas .panel-default .table:last-child,
// .offcanvas .panel-primary .table:last-child > tbody:last-child > tr:last-child,
// .offcanvas .panel-default .table:last-child > tbody:last-child > tr:last-child,
// .offcanvas
//   .panel-primary
//   .table:last-child
//   > tbody:last-child
//   > tr:last-child
//   td:last-child,
// .offcanvas
//   .panel-default
//   .table:last-child
//   > tbody:last-child
//   > tr:last-child
//   td:last-child {
//   border-radius: 0;
// }

// .offcanvas .panel-primary .table td,
// .offcanvas .panel-default .table td {
//   border-width: 0;
// }

// .offcanvas .panel-primary .table td:hover,
// .offcanvas .panel-default .table td:hover {
//   background-color: #eee;
// }

// .offcanvas .panel-primary .panel-heading,
// .offcanvas .panel-default .panel-heading {
//   border-top-left-radius: 0;
//   border-top-right-radius: 0;
//   border-bottom-right-radius: 0;
//   border-bottom-left-radius: 0;
//   background-color: transparent !important;
//   border-color: transparent;
// }

// .offcanvas .panel-primary .panel-heading .panel-title,
// .offcanvas .panel-default .panel-heading .panel-title {
//   font-weight: bold;
//   color: #131313;
// }

// .offcanvas .panel-primary .table,
// .offcanvas .panel-default .table {
//   margin-bottom: 0;
// }

// .offcanvas .panel-primary .table .radio,
// .offcanvas .panel-default .table .radio,
// .offcanvas .panel-primary .table .checkbox,
// .offcanvas .panel-default .table .checkbox {
//   margin-top: 0;
//   margin-bottom: 0;
// }

// .offcanvas .panel-primary .table .text-muted,
// .offcanvas .panel-default .table .text-muted,
// .offcanvas .panel-primary .table span.text-left,
// .offcanvas .panel-default .table span.text-left {
//   color: #4db4fa;
// }

// .offcanvas .panel-primary .table .text-muted,
// .offcanvas .panel-default .table .text-muted,
// .offcanvas .panel-primary .table span.text-right,
// .offcanvas .panel-default .table span.text-right {
//   color: #131313;
// }

// .offcanvas .panel-primary .table span.text-left:hover,
// .offcanvas .panel-default .table span.text-left:hover {
//   color: #4db4fa;
// }

// .offcanvas .navbar-default .panel-primary .table > tbody > tr > td {
//   padding: 0;
//   border-top-color: #36495a;
// }

// .scrollArea table tbody tr td {
//   background-color: transparent !important;
// }

// .cc-detail-task {
//   border: none !important;
//   padding: 1rem;
//   background: #ebeef0;
//   box-shadow: inset rem 0.1rem 0.5rem 0 rgba(0, 0, 0, 0.26);
//   border-radius: 0.5rem;
//   border: none;
// }

// .cc-detail-task .navbar.navbar-default {
//   background-color: #d0dae0;
//   box-shadow: 0 0.1rem 0.5rem 0 rgba(0, 0, 0, 0.26);
//   border: none;
// }

// #tsf-navbar {
//   padding: 0;
// }

// #tsf-navbar > .navbar-right {
//   padding-top: 0.8rem;
//   padding-bottom: 0.8rem;
// }

// container-fluid {
//   padding-right: 15px;
//   padding-left: 15px;
//   margin-right: auto;
//   margin-left: auto;
// }

// #offset > div > div > .container-fluid {
//   padding-left: 0;
//   padding-right: 0;
// }

// .cc-detail-task .modal-footer > .btn {
//   float: left;
// }

// select,
// .btn-default,
// .crew-date-formatter {
//   color: #333 !important;
//   border: none !important;
//   background: #ffffff !important;
//   background: -webkit-linear-gradient(#ffffff, #e6e6e6) !important;
//   background: -o-linear-gradient(#ffffff, #e6e6e6) !important;
//   background: -moz-linear-gradient(#ffffff, #e6e6e6) !important;
//   background: linear-gradient(#ffffff, #e6e6e6) !important;
//   box-shadow: 0 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.26) !important;
//   cursor: pointer;
// }

// input[type='checkbox'],
// input[type='radio'] {
//   box-shadow: none !important;
//   cursor: pointer;
// }

// select:hover,
// input:hover {
//   box-shadow: 0 0.4rem 0.5rem 0 rgba(0, 0, 0, 0.26) !important;
// }

// input[type='checkbox'],
// input[type='radio'] {
//   box-shadow: none !important;
// }

// .btn {
//   box-shadow: 0 0.2rem 0.5rem 0 rgba(0, 0, 0, 0.26);
// }

// .btn:hover {
//   box-shadow: 0 0.4rem 0.5rem 0 rgba(0, 0, 0, 0.26) !important;
// }

// .btn.btn-link {
//   box-shadow: none !important;
// }

// .btn-default {
//   color: #333;
//   border-color: #e6e6e6 !important;
//   background: #ffffff;
//   background: -webkit-linear-gradient(#ffffff, #e6e6e6);
//   background: -o-linear-gradient(#ffffff, #e6e6e6);
//   background: -moz-linear-gradient(#ffffff, #e6e6e6);
//   background: linear-gradient(#ffffff, #e6e6e6);
// }

// .btn-primary {
//   border-color: #0078d2 !important;
//   background: #0078d2;
//   background: -webkit-linear-gradient(#0078d2, #0065b3);
//   background: -o-linear-gradient(#0078d2, #0065b3);
//   background: -moz-linear-gradient(#0078d2, #0065b3);
//   background: linear-gradient(#0078d2, #0065b3);
// }

// .btn-success {
//   border-color: #00b989 !important;
//   background: #00b989;
//   background: -webkit-linear-gradient(#00b989, #009970);
//   background: -o-linear-gradient(#00b989, #009970);
//   background: -moz-linear-gradient(#00b989, #009970);
//   background: linear-gradient(#00b989, #009970);
// }

// .btn-warning {
//   border-color: #ff7318 !important;
//   background: #ff7318;
//   background: -webkit-linear-gradient(#ff7318, #e65c00);
//   background: -o-linear-gradient(#ff7318, #e65c00);
//   background: -moz-linear-gradient(#ff7318, #e65c00);
//   background: linear-gradient(#ff7318, #e65c00);
// }

// .btn-danger {
//   border-color: #f52305 !important;
//   background: #f52305;
//   background: -webkit-linear-gradient(#f52305, #c81e04);
//   background: -o-linear-gradient(#f52305, #c81e04);
//   background: -moz-linear-gradient(#f52305, #c81e04);
//   background: linear-gradient(#f52305, #c81e04);
// }

// .btn-default:hover {
//   border-color: #d9d9d9 !important;
//   background: #f2f2f2;
//   background: -webkit-linear-gradient(#f2f2f2, #d9d9d9);
//   background: -o-linear-gradient(#f2f2f2, #d9d9d9);
//   background: -moz-linear-gradient(#f2f2f2, #d9d9d9);
//   background: linear-gradient(#f2f2f2, #d9d9d9);
// }

// .btn-primary:hover {
//   background: #0074cc;
//   background: -webkit-linear-gradient(#0074cc, #005799);
//   background: -o-linear-gradient(#0074cc, #005799);
//   background: -moz-linear-gradient(#0074cc, #005799);
//   background: linear-gradient(#0074cc, #005799);
// }

// .btn-success:hover {
//   background: #00b383;
//   background: -webkit-linear-gradient(#00b383, #00805e);
//   background: -o-linear-gradient(#00b383, #00805e);
//   background: -moz-linear-gradient(#00b383, #00805e);
//   background: linear-gradient(#00b383, #00805e);
// }

// .btn-warning:hover {
//   background: #ff6600;
//   background: -webkit-linear-gradient(#ff6600, #cc5200);
//   background: -o-linear-gradient(#ff6600, #cc5200);
//   background: -moz-linear-gradient(#ff6600, #cc5200);
//   background: linear-gradient(#ff6600, #cc5200);
// }

// .btn-danger:hover {
//   background: #e12205;
//   background: -webkit-linear-gradient(#e12205, #af1a04);
//   background: -o-linear-gradient(#e12205, #af1a04);
//   background: -moz-linear-gradient(#e12205, #af1a04);
//   background: linear-gradient(#e12205, #af1a04);
// }

// .btn .caret {
//   float: right;
//   top: 0.9rem;
//   position: relative;
// }

// .table-striped > tbody > tr:nth-of-type(odd) {
//   background-color: #ebeef0;
// }

// .nav-pills > li.active > a,
// .nav-pills > li.active > a:focus,
// .nav-pills > li.active > a:hover {
//   background-color: #0078d2;
// }

// .aa-pad-left-lg {
//   padding-left: 1.5rem;
// }

// #ccDetailTaskInstructions {
//   border: 0.1rem solid #ccc !important;
// }

// .blink {
//   animation: blinker 1s linear infinite;
// }

// @keyframes blinker {
//   50% {
//     opacity: 0;
//   }
// }

// .virtualRepeatdemoDeferredLoading #vertical-container {
//   height: 29.2rem;
//   width: 100%;
//   max-width: 40rem;
// }

// .virtualRepeatdemoDeferredLoading .repeated-item {
//   border-bottom: 0.1rem solid #ddd;
//   box-sizing: border-box;
//   height: 2rem;
//   padding-top: 1rem;
// }

// .virtualRepeatdemoDeferredLoading md-content {
//   margin: 1.6rem;
// }

// .virtualRepeatdemoDeferredLoading md-virtual-repeat-container {
//   border: solid 0.1rem grey;
// }

// .virtualRepeatdemoDeferredLoading
//   .md-virtual-repeat-container
//   .md-virtual-repeat-offsetter
//   div {
//   padding-left: 1.6rem;
// }

// .md-virtual-repeat-offsetter {
//   height: 67.8rem;
// }

// .takeOwnershipDisabled {
//   pointer-events: none;
//   cursor: default;
//   background-color: lightgray;
// }

// .redPhone,
// .redPhone:hover {
//   color: red;
//   text-decoration: none;
// }

// .greenPhone,
// .greenPhone:hover {
//   color: green;
//   text-decoration: none;
// }

// .acquamarineBackground {
//   background-color: aquamarine;
// }

// .blueBackground {
//   background-color: lightskyblue;
// }

// .redBorder {
//   border-style: solid;
//   border-color: red;
//   border-width: 0.2rem;
// }

// .redBackgroundWhiteText {
//   font-size: 1.2rem;
//   font-weight: normal;
//   background-color: red;
//   color: white;
//   border-radius: 0.5rem;
//   padding: 0.2rem;
// }

// .manualLabelInput {
//   font-weight: bold;
//   padding-left: 0.5rem;
//   border: 0.1rem solid #ccc !important;
// }

// .mdChipsUsage .md-chips md-chip {
//   border-radius: 0.3rem;
//   margin: 0.2rem 0.2rem 0 0.2rem;
//   background: linear-gradient(white, #005ed2);
//   font-weight: bold;
//   width: 10rem;
//   font-family: AmericanSansLight, Helvetica, sans-serif;
//   font-size: 1.8rem;
// }

// .mdChipsUsage .md-chips .md-chip-input-container {
//   border: solid;
//   padding-left: 0.5rem;
//   border-width: 0.2rem;
//   font-weight: bold;
// }

// .h3-2 {
//   font-size: 1.8rem;
//   font-family: inherit;
//   font-weight: 500;
//   line-height: 1.1;
//   color: inherit;
//   padding: 0.3rem;
// }

// .nonAcTask {
//   margin-right: 0.5rem;
//   padding: 0.4rem;
// }

// .sortorder:after {
//   content: '\25b2';
// }

// .sortorder.reverse:after {
//   content: '\25bc';
// }

// .nonACColumnHeader {
//   color: #76b3f6;
//   cursor: pointer;
//   text-transform: uppercase;
// }

// .alternateFieldSet {
//   display: inline-block;
//   vertical-align: top;
// }

// .alternateFieldSetButton {
//   display: inline-block;
//   padding-left: 1rem;
// }

// .alternateFilterAlign {
//   text-align: center;
//   padding-left: 0.5rem;
// }

// .alternateTextAlignWidth {
//   text-align: center;
//   padding-left: 0.5rem;
//   width: 13rem;
// }

// .alternateElement {
//   display: inline-block;
//   background: linear-gradient(#0074cc, #005799);
//   margin-right: 10rem;
//   padding-top: 0.4rem;
//   padding-right: 1.2rem;
//   padding-bottom: 1.2rem;
//   padding-left: 1.1rem;
//   font-size: medium;
//   color: white;
//   font-weight: normal;
//   height: 3.2rem;
// }

// .alternateCheckbox {
//   transform: scale(1.6);
//   width: 3rem;
// }

//#endregion

//#region  scrollable-table.css (ScrollableArea CC-View)

// th .orderWrapper {
//   position: absolute;
//   top: 0;
//   right: 0.2rem;
//   cursor: pointer;
// }

// th .orderWrapper .order {
//   font-size: 8pt;
//   color: #bdbdbd;
// }

// th .orderWrapper .active {
//   color: #464646;
// }

// .scaler {
//   position: absolute;
//   top: 0;
//   width: $two;
//   height: 100%;
//   background-color: #cfcfcf;
// }

// .scrollable-resizing {
//   cursor: col-resize;
//   -moz-user-select: none;
//   -webkit-user-select: none;
//   -ms-user-select: none;
// }

//#endregion  scrollable-table.css (ScrollableArea CC-View)

//#region NEW LMO CSS

//#endregion Global Header
