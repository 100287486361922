@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'ngx-toastr/toastr';
@import 'common-theme';

@media (prefers-color-scheme: light) {
  @import 'light-theme';
}

@media (prefers-color-scheme: dark) {
  @import 'dark-theme';
}
